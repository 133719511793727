import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, CircularProgress, Grid, Paper } from '@material-ui/core';
import { Entity, EntityField, IEntityField } from 'icerockdev-admin-toolkit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type NewsEntityEditorProps = {
  url: string;
  id?: string;
  fields: IEntityField[];
  editorFieldName: string;
  errors: Record<string, string>;
  isEditing: boolean;
  isLoading: boolean;
  data: Record<string, any>;
  viewable: boolean;
  entity: Entity;
  header: string;

  setEditorData: (data: Record<string, any>) => void;
  getItem: (id: any) => void;
  cancelGetItem: () => void;
  onSave: () => void;
  onCancel: () => void;
  onResetFieldError: (field: string) => void;
  withToken?: (req: any, args: any) => any;
};

const NewsEntityEditor: FC<NewsEntityEditorProps> = ({
  id,
  fields,
  errors,
  onSave,
  onCancel,
  onResetFieldError,
  isLoading,
  data,
  setEditorData,
  getItem,
  cancelGetItem,
  withToken,
  isEditing,
  entity,
  header,
  editorFieldName,
}) => {
  const { t } = useTranslation();
  const isCreating = useMemo(() => typeof id === 'undefined', [id]);

  const visibleFields = useMemo(
    () =>
      fields.filter(
        (field) =>
          (isEditing && !isCreating && !field.hideInEdit) ||
          (isCreating && !field.hideInCreate) ||
          (!isEditing && !isCreating && !field.hideInView)
      ),
    [fields, isEditing, isCreating]
  );

  const onFieldChange = useCallback(
    (f) => (value: any) => {
      if (errors[f]) {
        onResetFieldError(f);
      }

      setEditorData({
        ...data,
        [f]: value,
      });
    },
    [errors, setEditorData, data, onResetFieldError]
  );

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      onSave();
    },
    [onSave]
  );

  useEffect(() => {
    getItem(id);
    return () => cancelGetItem();
  }, [cancelGetItem, getItem, id]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {data && (
        <form onSubmit={onSubmit}>
          <div className={styles.wrap}>
            <Paper>
              <h1>{header}</h1>
              <div className={styles.formwrap} style={{ flexWrap: 'wrap' }}>
                {visibleFields.map(
                  (field) =>
                    field.name !== editorFieldName && (
                      <div className={styles.field} key={field.name}>
                        {!isEditing && (
                          <div className="label">
                            {field.label || field.name}
                            {isEditing && field.required && <span>{' *'}</span>}
                          </div>
                        )}
                        <div className={styles.entityfield}>
                          <EntityField
                            name={field.name}
                            data={data}
                            fields={fields}
                            isEditing={isEditing}
                            error={errors[field.name]}
                            handler={onFieldChange(field.name)}
                            withToken={withToken}
                            entity={entity}
                          />
                        </div>
                      </div>
                    )
                )}
              </div>
            </Paper>
          </div>

          <div className={styles.wrap}>
            <Paper className={styles.editor}>
              <div className={styles.formwrap} style={{ flexWrap: 'wrap' }}>
                {isEditing && (
                  <>
                    <div>
                      <EntityField
                        name={editorFieldName}
                        data={data}
                        fields={fields}
                        isEditing={isEditing}
                        error={errors[editorFieldName]}
                        handler={onFieldChange(editorFieldName)}
                        withToken={withToken}
                        entity={entity}
                      />
                    </div>
                    <div className={styles.buttons}>
                      <Grid container spacing={1}>
                        <Grid item style={{ flex: 1 }} />

                        <Grid item>
                          <Button
                            type="button"
                            color="default"
                            variant="outlined"
                            onClick={onCancel}
                          >
                            {t('buttons:Cancel')}
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button type="submit" variant="contained" color="primary">
                            {t('buttons:Save')}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </div>
            </Paper>
          </div>
        </form>
      )}
    </>
  );
};

export { NewsEntityEditor };
