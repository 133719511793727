import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import styles from './styles.module.scss';

interface NewsProps {
  id: number;
  data: Record<string, any>;
  getItem: (id: any) => void;
  cancelGetItem: () => void;
}

const NewsViewer = observer(({ id, data, getItem, cancelGetItem }: NewsProps) => {
  useEffect(() => {
    getItem(id);
    return () => cancelGetItem();
  }, [cancelGetItem, getItem, id]);

  function createMarkup() {
    return { __html: data?.contentFull };
  }

  return (
    <div className={styles.wrap}>
      <div>
        <span className={styles.header_news}>
          {`${moment(data?.publishedAt).format('DD.MM.YYYY HH:mm')} | ${data?.author}`}
        </span>
      </div>
      <div>
        <h1 className={styles.title}>{data?.title}</h1>
      </div>
      <div className={styles.sontent_preview_img}>
        <img src={data?.contentPreviewImg} alt="" />
      </div>
      <div>
        <span className={styles.content_preview_img__caption}>
          {data?.contentPreviewImgCaption}
        </span>
      </div>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
});

export { NewsViewer };
