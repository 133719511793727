import React, { FC, useCallback, HTMLAttributes, useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps extends HTMLAttributes<HTMLTextAreaElement> {
  value: string;
  name: string;
  hasError?: boolean;
  error?: string;
  handler: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
}

const Textarea: FC<IProps> = ({ value, label, handler, hasError, name }) => {
  const [focused, setFocused] = useState(false);

  const onFocus = useCallback(() => setFocused(true), [setFocused]);
  const onBlur = useCallback(() => setFocused(false), [setFocused]);
  const onChange = useCallback((event) => handler(event), [handler]);
  return (
    <div
      className={classNames(styles.wrap, {
        [styles.has_error]: hasError,
      })}
    >
      {label && (
        <div
          className={classNames(styles.label, {
            [styles.focused]: focused || !!value,
          })}
        >
          {label}
        </div>
      )}

      <div className={styles.input__error}>{hasError && 'Заполните это поле'}</div>

      <div className={styles.textarea__block}>
        <textarea
          name={name}
          value={value}
          required
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export { Textarea };
