import Axios from 'axios';
import qs from 'query-string';
import { projectErrorInterceptor } from '~/utils/interceptor';

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.rss.dev.icerockdev.com',
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
});

axios.interceptors.response.use((response) => response, projectErrorInterceptor);
