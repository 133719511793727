/* eslint-disable no-useless-escape */
// eslint-disable-next-line no-shadow
export enum UserRole {
  SuperAdmin = 'SUPERADMIN',
  AppAdmin = 'APPADMIN',
}

// eslint-disable-next-line no-shadow
export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export const USER_STATUS_NAMES = {
  [UserStatus.Active]: 'Активный',
  [UserStatus.Blocked]: 'Заблокирован',
};

// eslint-disable-next-line no-shadow
export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export const SUBSCRIPTION_STATUS_NAMES = {
  [SubscriptionStatus.Active]: 'Активная',
  [SubscriptionStatus.Blocked]: 'Заблокированная',
};

// eslint-disable-next-line no-shadow
export enum SubscriptionType {
  MONTH_6 = 'MONTH_6',
  YEAR = 'YEAR',
}

export const SUBSCRIPTION_TYPE_NAMES = {
  [SubscriptionType.MONTH_6]: '6 мес.',
  [SubscriptionType.YEAR]: 'Год',
};

export interface Subscription {
  id: number;
  appId: number;
  adminId: number;
  expiredAt: string;
  startAt: string;
  status: SubscriptionStatus;
}

export interface User {
  id: number;
  appId: number;
  status: UserStatus;
  role: UserRole;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
  website: string;
  subscription: Subscription;
  subscriptionHistory: Subscription[];
  subscriptionType: SubscriptionType;
}

export const MIN_PASSWORD_LENGTH = 8;
export const MIN_STRING_LENGTH = 1;
export const MAX_NAME_LENGTH = 30;
export const MAX_WEBSITE_LENGTH = 500;
export const PHONE_NUMBER_LENGTH = 11;
export const ONLY_CYRILLIC_SYMBOLS = /^([а-яА-Я]{0,30})$/;
export const WEBSITE_SYMBOLS = /([a-zA-Z0-9:\/-]+\.)+([a-zA-Z0-9/]+)/;
