import { RenderElementProps } from 'slate-react/dist/components/editable';
import React from 'react';
import styles from './styles.module.scss';

const Paragraph = ({ attributes, children }: RenderElementProps) => (
  <p className={styles.paragraph} {...attributes}>
    {children}
  </p>
);

export { Paragraph };
