import {
  IEntityGetFunction,
  IEntityGetFunctionProps,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const getProfile: IEntityGetFunction = async ({ url, token }: IEntityGetFunctionProps) => {
  const result = await axios.get(`${url}`, {
    headers: {
      authorization: token,
    },
  });

  return {
    data: result?.data?.data || {},
    error: '',
  };
};

export const getCheckSubscriptionExpire: IEntityGetFunction = async ({
  url,
  token,
}: IEntityGetFunctionProps) => {
  const result = await axios.get(`${url}`, {
    headers: {
      authorization: token,
    },
  });

  return {
    data: result?.data?.data || {},
    error: '',
  };
};

export const updatePasswordUser = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(`${url}/${data.id}`, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};
