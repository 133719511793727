import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityGetFunctionProps,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import NotificationError from '~/utils/notificationError';
import { authorizedHandler } from '~/utils/interceptor';
import { NEWS_PER_PAGE } from '~/pages/feed/constants';

export const fetchNews: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const limit = count ? (page + 1) * count : 25;
  const orderBy = sortDir?.toUpperCase();
  // noinspection TypeScriptValidateTypes
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || {};

  // fetching users
  const response = await axios
    .get(url, {
      params: {
        offset,
        limit,
        ...(sortBy ? { sortBy, orderBy } : {}),
        ...filters,
      },
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  const list = response?.data?.data || [];

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};

interface IEntityGetWithListFunctionProps extends IEntityGetFunctionProps {
  page?: number;
  count?: number;
}

export const getNews: IEntityGetFunction = async ({
  url,
  id,
  token,
  page,
}: IEntityGetWithListFunctionProps) => {
  const offset = page ? page * NEWS_PER_PAGE : 0;
  const result = await axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
    params: {
      offset,
      limit: NEWS_PER_PAGE,
    },
  });

  return {
    data: result?.data?.data || {},
    error: '',
  };
};

export const updateNews = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const payload = {
    name: data.name,
    url: data.url,
    description: data.description,
    emojiCode: data.emojiCode,
  };

  const result = await axios
    .put(`${url}/${data.id}`, payload, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};

export const createNews = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios
    .post(url, data, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  if (result?.data.success === 'false') {
    throw new NotificationError(result?.data.status, result?.data.message);
  }

  return {
    data: result.data,
  };
};

export const deleteNewsApi = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.delete(`${url}/${data}`, {
    headers: { authorization: token },
  });

  if (result?.data.success === 'false') {
    throw new NotificationError(result?.data.status, result?.data.message);
  }

  return {
    data: result.data,
  };
};

export const addNotificationApi = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios
    .post(url, data, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};
