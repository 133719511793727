import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { Button } from '@material-ui/core';
import { useEntity } from 'icerockdev-admin-toolkit';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { UserEntity } from '~/pages/user/components/UserEntity';
import styles from './styles.module.scss';

interface IProps {
  data: Record<string, any> | undefined;
  children: ReactNode;
}

const ModalUserBlock: FC<IProps> = ({ data, children }) => {
  const fullName = [data?.firstName, data?.middleName, data?.lastName].join(' ');
  const entity = useEntity<UserEntity>();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const { t } = useTranslation();

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setOpen(false);
      entity.blockItem(data?.id || 0);
    },
    [entity, data?.id]
  );

  const modalButton = useMemo(() => {
    if (React.isValidElement(children)) {
      // noinspection TypeScriptValidateTypes
      return React.cloneElement(children, {
        onClick: (event) => {
          event.stopPropagation();
          setOpen(true);
        },
      });
    }
    return null;
  }, [children]);

  return (
    <>
      {modalButton}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={styles.modal_title} id="alert-dialog-title">
          {t('Blocking a user')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.modal_desc} id="alert-dialog-description">
            {`${t('messages:Are you sure you want to block the user')} `}
            {` ${fullName} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={styles.button__disagree} onClick={(e) => handleClose(e)}>
            {t('buttons:Cancel')}
          </Button>
          <Button className={styles.button__agree} onClick={(e) => onClick(e)}>
            {t('buttons:Block')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ModalUserBlock };
