import React, { FC } from 'react';
import { EntityFieldString, IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { UserEntity } from '~/pages/user/components/UserEntity';

interface IProps extends IEntityFieldProps {
  entity: UserEntity;
}

const UserNameField: FC<IProps> = ({
  data,
  isFiltering,
  isEditing,
  name,
  label,
  value,
  handler,
  fields,
}) => {
  if (isFiltering) {
    return (
      <EntityFieldString
        name={name}
        label={label}
        value={value}
        isEditing={isEditing}
        handler={handler}
        fields={fields}
      />
    );
  }

  const fullName = [data?.lastName, data?.firstName, data?.middleName].join(' ');

  return <div>{fullName}</div>;
};

export { UserNameField };
