import { createNews, fetchNews, getNews, updateNews } from './api';
import { NEWS_FIELDS } from './fields';
import { FeedEntity } from '~/pages/feed/components/FeedEntity';
import { UserRole } from '~/pages/user/constants';

export default new FeedEntity({
  title: 'Бесплатные разделы',
  viewable: true,
  editable: true,
  creatable: true,
  exportable: false,
  items: 25,
  api: {
    list: { url: '/admin/v1/news/feeds', method: 'get' },
    get: {
      url: '/admin/v1/news/feeds',
      method: 'get',
    },
    create: {
      url: '/admin/v1/news/feeds',
      method: 'post',
    },
    update: {
      url: '/admin/v1/news/feeds',
      method: 'put',
    },
    delete: {
      url: '/admin/v1/news',
      method: 'delete',
    },
    deleteFeed: {
      url: '/admin/v1/news/feeds',
      method: 'delete',
    },
    addNotification: {
      url: '/admin/v1/notification',
      method: 'post',
    },
  },
  menu: {
    label: 'Бесплатные разделы',
    url: '/feeds',
    enabled: true,
  },
  roles: [UserRole.AppAdmin],
  permissions: {
    list: [UserRole.AppAdmin],
  },
  fields: NEWS_FIELDS,
  fetchItemsFn: fetchNews,
  getItemsFn: getNews,
  updateItemsFn: updateNews,
  createItemsFn: createNews,
});
