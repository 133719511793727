import { NewsEntity } from '~/pages/news/components/NewsEntity';
import { NEWS_FIELDS } from './fields';
import { UserRole } from '~/pages/user/constants';
import { createNews, fetchNews, getNews, updateNews } from './api';

const News = new NewsEntity({
  title: 'Платный контент',
  fields: NEWS_FIELDS,
  viewable: true,
  editable: true,
  creatable: true,
  exportable: false,
  items: 50,
  menu: {
    enabled: true,
    url: '/news',
    label: 'Платный контент',
  },
  api: {
    list: { url: '/admin/v1/news', method: 'get' },
    create: { url: '/admin/v1/news', method: 'post' },
    get: {
      url: '/admin/v1/news',
      method: 'get',
    },
    update: {
      url: '/admin/v1/news',
      method: 'put',
    },
    delete: {
      url: '/admin/v1/news',
      method: 'delete',
    },
    addFile: {
      url: '/admin/v1/file',
      method: 'post',
    },
    addNotification: {
      url: '/admin/v1/notification',
      method: 'post',
    },
  },
  roles: [UserRole.AppAdmin],
  permissions: {
    list: [UserRole.AppAdmin],
  },
  fetchItemsFn: fetchNews,
  getItemsFn: getNews,
  updateItemsFn: updateNews,
  createItemsFn: createNews,
});

export default News;
