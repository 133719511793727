import React, { useCallback, useState } from 'react';
import { useSlate } from 'slate-react';
import { IconButton } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { insertLink } from '../../../../slate/editor';

export const LinkButton = () => {
  const editor = useSlate();
  const [inputsState, setInputsState] = useState<Record<string, string>>({});
  const { name, link } = inputsState;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    const nameInput = e.target.name;
    const { value } = e.target;

    setInputsState((prevState) => ({ ...prevState, [nameInput]: value }));
  };

  const addLink = () => {
    insertLink(editor, inputsState);
  };

  return (
    <>
      <IconButton onClick={onClick}>
        <LinkIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Добавление Ссылки</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              'messages:Please note that you cannot edit the link, you can only delete and add a new one'
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="link"
            label="Ссылка"
            name="link"
            type="text"
            fullWidth
            value={link}
            onChange={onChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={name}
            name="name"
            label="Текст ссылки"
            type="text"
            fullWidth
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            {t('buttons:Cancel')}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              addLink();
            }}
            color="primary"
            variant="contained"
          >
            {t('buttons:Add link')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
