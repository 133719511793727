import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import styles from './styles.module.scss';
import { UserEntity } from '~/pages/user/components/UserEntity';
import { UserStatus } from '~/pages/user/constants';
import { ModalUserBlock } from '../ModalUserBlock';

const UserBlockButton: FC<IEntityFieldProps> = ({ data }) => {
  const entity = useEntity<UserEntity>();

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      entity.unBlockItem(data?.id || 0);
    },
    [data, entity]
  );

  return (
    <div className={styles.wrap}>
      {data?.status === UserStatus.Active ? (
        <ModalUserBlock data={data}>
          <Button color="secondary">
            <LockIcon />
          </Button>
        </ModalUserBlock>
      ) : (
        <Button
          style={{ color: 'green' }}
          onClick={(event) => {
            event.stopPropagation();
            onClick(event);
          }}
        >
          <LockOpenIcon />
        </Button>
      )}
    </div>
  );
};

export { UserBlockButton };
