import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { saveAs } from 'file-saver';
import styles from './styles.module.scss';

const DounloadApp = observer(({ getSetup, getVersion }) => {
  const [isLoading, setLoading] = useState(false);
  const [infoVersion, setInfoVersion] = useState({
    appVersion: '',
    updates: [],
  });
  const history = useHistory();
  const setup = async () => {
    setLoading(true);
    await getSetup().then((res) => {
      saveAs(res, 'build.zip');
      setLoading(false);
      history.push('/customization/application-instructions');
    });
  };

  useEffect(() => {
    (async () => {
      const info = await getVersion();
      setInfoVersion(info);
    })();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.wrap_loader}>
        <div className={styles.loader}>
          <CircularProgress />
        </div>
        <h4>Ожидайте, загрузка уже началась</h4>
      </div>
    );
  }
  return (
    <div className={styles.wrap}>
      <h1>Скачивание приложения</h1>
      <p>Последнее скачивание: 23.01.2021</p>
      <h3>
        Последняя версия приложения:
        {infoVersion?.appVersion}
      </h3>
      <ul>
        {infoVersion.updates.map((list) => (
          <li>{list}</li>
        ))}
      </ul>
      <Button variant="contained" color="primary" onClick={setup} className={styles.button_setup}>
        Cкачать
      </Button>
    </div>
  );
});

export { DounloadApp };
