import { createMuiTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

export default createMuiTheme({
  spacing: 4,
  palette: {
    secondary: {
      main: red.A700,
    },
    error: {
      main: red.A700,
    },
    success: {
      main: green.A700,
    },
  },
  overrides: {
    MuiButton: {
      textPrimary: {
        color: 'white',
      },
    },
  },
});
