import { RenderElementProps } from 'slate-react/dist/components/editable';
import React from 'react';
import styles from './styles.module.scss';

const Title = ({ attributes, children }: RenderElementProps) => (
  <h2 className={styles.title} {...attributes}>
    {children}
  </h2>
);

export { Title };
