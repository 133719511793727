import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Subscription } from '~/pages/user/constants';

interface SubscriptionRemainingProps {
  data: Subscription;
}

const SubscriptionRemaining: FC<SubscriptionRemainingProps> = ({ data }) => {
  const { t } = useTranslation();
  const [dateDifference, setDateDifference] = useState<number>();
  const startAt = moment(data?.startAt).format('DD.MM.YYYY');
  const expiredAt = moment(data?.expiredAt).format('DD.MM.YYYY');
  const today = moment(new Date());

  useEffect(() => {
    setDateDifference(moment(data?.expiredAt).diff(today, 'days'));
  }, [data?.expiredAt]);

  return (
    <div className={styles.wrap}>
      <div>
        <h3 className={styles.title}>
          {t('messages:Remaining {{count}} days of subscription', { count: dateDifference })}
        </h3>
      </div>
      <div className={styles.desc}>
        {t('From {{from}} to {{to}}', { from: startAt, to: expiredAt })}
      </div>
    </div>
  );
};

export { SubscriptionRemaining };
