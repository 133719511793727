import React, { FC } from 'react';
import { EntityFieldSelect, IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { UserEntity } from '~/pages/user/components/UserEntity';

interface IProps extends IEntityFieldProps {
  entity: UserEntity;
}

// TODO: Move to pages/subscription/components
const SubscriptionStatusField: FC<IProps> = ({
  data,
  name,
  label,
  value,
  handler,
  error,
  onClick,
  options,
  fields,
  isFiltering,
  isEditing,
}) => {
  if (isFiltering) {
    return (
      <EntityFieldSelect
        name={name}
        label={label}
        value={value}
        handler={handler}
        error={error}
        onClick={onClick}
        options={options}
        fields={fields}
        isFiltering={isFiltering}
        isEditing={isEditing}
      />
    );
  }

  const status = options && data?.subscription ? options[data.subscription.status] : '';

  return <div>{status}</div>;
};

export { SubscriptionStatusField };
