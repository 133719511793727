import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';

// eslint-disable-next-line no-shadow
export enum EditorElements {
  Paragraph = 'paragraph',
  Title = 'title',
  Blockquote = 'blockquote',
  Image = 'image',
}

export type BoldText = { bold: boolean };
export type ItalicText = { italic: boolean };
export type UnderlineText = { underline: boolean };
export type StrikethroughText = { strikethrough: boolean };
export type LinkUrl = { url: string };

export type LeafText = BoldText & ItalicText & UnderlineText & StrikethroughText & LinkUrl;

export type ParagraphText = { text: string } & LeafText;
export type TitleText = { text: string } & LeafText;
export type BlockquoteText = { text: string };
export type ImageField = { text: string };

export type ParagraphElement = { type: EditorElements.Paragraph; children: ParagraphText[] };
export type TitleElement = { type: EditorElements.Title; children: TitleText[] };
export type BlockquoteElement = { type: EditorElements.Blockquote; children: BlockquoteText[] };
export type ImageElement = { type: EditorElements.Image; url: string; children: ImageField[] };

declare module 'slate' {
  export interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: ParagraphElement | TitleElement | BlockquoteElement | ImageElement;
    Text: ParagraphText | TitleText | BlockquoteText;
  }
}
