import { createSubscription, createUser, fetchUsers, getUser, updateUser } from './api';
import { USER_FIELDS } from './fields';
import { UserEntity } from '~/pages/user/components/UserEntity';
import { UserRole } from '~/pages/user/constants';

export default new UserEntity({
  title: 'Пользователи',
  viewable: true,
  editable: true,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: '/admin/v1/user', method: 'get' },
    get: { url: '/admin/v1/user', method: 'get' },
    create: { url: '/admin/v1/user', method: 'post' },
    update: { url: '/admin/v1/user', method: 'put' },
    delete: { url: '/admin/v1/user', method: 'delete' },
    subscriptionCreate: { url: '/admin/v1/subscription', method: 'post' },
    subscriptionUnBlock: { url: '/admin/v1/subscription', method: 'put' },
    subscriptionBlock: { url: '/admin/v1/subscription', method: 'delete' },
  },
  menu: {
    label: 'Пользователи',
    url: '/user',
    enabled: true,
  },
  roles: [UserRole.SuperAdmin],
  permissions: {
    list: [UserRole.SuperAdmin],
  },
  fields: USER_FIELDS,
  fetchItemsFn: fetchUsers,
  getItemsFn: getUser,
  updateItemsFn: updateUser,
  createItemsFn: createUser,
  createSubscriptionFn: createSubscription,
});
