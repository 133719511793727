import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import moment from 'moment';
import { UserEntity } from '~/pages/user/components/UserEntity';

interface IProps extends IEntityFieldProps {
  entity: UserEntity;
}

// TODO: Move to pages/subscription/components
const SubscriptionTimeField: FC<IProps> = ({ data }) => {
  const startAt = data?.subscription?.startAt
    ? moment(data?.subscription?.startAt).format('DD.MM.YYYY')
    : '-';
  const expiredAt = data?.subscription?.expiredAt
    ? moment(data?.subscription?.expiredAt).format('DD.MM.YYYY')
    : '-';

  return <div>{`${startAt} / ${expiredAt}`}</div>;
};

export { SubscriptionTimeField };
