import { IEntityField } from 'icerockdev-admin-toolkit';
import { minMaxLength } from '~/utils/validators';
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH, MIN_STRING_LENGTH } from '~/pages/feed/constants';
import { EmojiField } from './components/EmojiField';
import { ButtonDeleteFeed } from './components/ButtonDeleteFeed';

export const NEWS_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'name',
    label: 'Название раздела',
    title: true,
    hideInView: true,
    required: true,
    validator: minMaxLength(
      MIN_STRING_LENGTH,
      MAX_NAME_LENGTH
    )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_NAME_LENGTH} символов`),
  },
  {
    type: 'string',
    name: 'url',
    label: 'Ссылка на RSS ленту',
    hideInView: true,
    required: true,
  },
  {
    type: 'string',
    name: 'description',
    label: 'Описание',
    hideInList: true,
    required: true,
    validator: minMaxLength(
      MIN_STRING_LENGTH,
      MAX_DESCRIPTION_LENGTH
    )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_DESCRIPTION_LENGTH} символов`),
  },
  {
    type: 'custom',
    name: 'emojiCode',
    label: 'Emoji',
    hideInView: true,
    hideInList: true,
    hideInEdit: false,
    component: EmojiField,
  },
  {
    type: 'custom',
    name: 'buttonDelete',
    label: ' ',
    hideInView: true,
    hideInCreate: true,
    component: ButtonDeleteFeed,
  },
];
