import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { createEditor, Descendant } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import { setRef } from '@material-ui/core';
import {
  FormatBold,
  FormatItalic,
  FormatQuote,
  FormatStrikethrough,
  FormatUnderlined,
  Title as FormatTitle,
} from '@material-ui/icons';
import { RenderElementProps, RenderLeafProps } from 'slate-react/dist/components/editable';
import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { Blockquote } from '~/pages/news/components/NewsWysiwygField/components/Blockquote';
import { ImageButton } from '~/pages/news/components/NewsWysiwygField/components/ImageButton';
import { Paragraph } from '~/pages/news/components/NewsWysiwygField/components/Paragraph';
import { Title } from '~/pages/news/components/NewsWysiwygField/components/Title';
import {
  Leaf,
  RenderLeafPropsExtended,
} from '~/pages/news/components/NewsWysiwygField/components/Leaf';
import { EditorElements } from '~/pages/news/slate/types';
import { ElementButton } from '~/pages/news/components/NewsWysiwygField/components/ElementButton';
import { Image } from '~/pages/news/components/NewsWysiwygField/components/Image';
import { deserialize, serialize } from '~/pages/news/slate/editor';
import { LinkButton } from './components/LinkButton';
import { LeafButton } from './components/LeafButton';

const initialState: Descendant[] = [
  {
    type: EditorElements.Paragraph,
    children: [
      {
        text: '',
        bold: false,
        italic: false,
        underline: false,
        strikethrough: false,
        url: '',
      },
    ],
  },
];

const NewsWysiwygField: FC<IEntityFieldProps> = ({
  label,
  value,
  handler,
  error,
  isEditing,
  onClick,
}) => {
  const isInitialMount = useRef<boolean>(true);
  const editor = useMemo(() => withReact(createEditor()), []);
  const [editorValue, setEditorValue] = useState<Descendant[]>(initialState);
  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;
  useEffect(() => {
    if (value && isInitialMount) {
      const document = new DOMParser().parseFromString(value, 'text/html');
      const data = Array.from(document.body.children).map(deserialize);
      setEditorValue(data);
      setRef(isInitialMount, false);
    }
  }, [value, isInitialMount, id]);

  const onChange = useCallback(
    (data) => {
      setEditorValue(data);
      if (!handler) return;
      handler(serialize(data) || '');
    },
    [handler, setEditorValue, editorValue]
  );

  const locale = useLocation();

  useEffect(() => {
    if (locale.pathname.indexOf('create') !== -1) {
      setEditorValue(initialState);
    }
  }, [locale.pathname]);

  const renderElement = useCallback(({ children, element, ...props }: RenderElementProps) => {
    switch (element.type) {
      case EditorElements.Image:
        return (
          <Image {...props} element={element}>
            {children}
          </Image>
        );
      case EditorElements.Title:
        return (
          <Title {...props} element={element}>
            {children}
          </Title>
        );
      case EditorElements.Blockquote:
        return (
          <Blockquote {...props} element={element}>
            {children}
          </Blockquote>
        );
      case EditorElements.Paragraph:
      default:
        return (
          <Paragraph {...props} element={element}>
            {children}
          </Paragraph>
        );
    }
  }, []);

  const renderLeaf = useCallback(
    ({ children, ...props }: RenderLeafProps) => (
      <Leaf {...(props as RenderLeafPropsExtended)}>{children}</Leaf>
    ),
    []
  );

  return isEditing ? (
    <div>
      <Slate editor={editor} value={editorValue} onChange={onChange}>
        <div className={styles.buttons}>
          <LeafButton name="bold">
            <FormatBold />
          </LeafButton>
          <LeafButton name="italic">
            <FormatItalic />
          </LeafButton>
          <LeafButton name="underline">
            <FormatUnderlined />
          </LeafButton>
          <LeafButton name="strikethrough">
            <FormatStrikethrough />
          </LeafButton>
          <ElementButton type={EditorElements.Title}>
            <FormatTitle />
          </ElementButton>
          <ElementButton type={EditorElements.Blockquote}>
            <FormatQuote />
          </ElementButton>
          <LinkButton />
          <ImageButton />
        </div>
        <Editable renderElement={renderElement} renderLeaf={renderLeaf} />
      </Slate>
    </div>
  ) : (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line react/no-danger,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    (value && <div onClick={onClick} dangerouslySetInnerHTML={{ __html: value }} />) || (
      <div>&nbsp;</div>
    )
  );
};

export { NewsWysiwygField };
