import React, { FC, useCallback } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const PhoneField: FC<IEntityFieldProps> = ({
  label,
  value,
  handler,
  error,
  isEditing,
  onClick,
}) => {
  const onChange = useCallback(
    (event) => {
      if (!handler) return;

      handler(event.target.value.replace(/[^\d]/gim, ''));
    },
    [handler]
  );

  const phone = value?.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');

  return isEditing ? (
    <div>
      <InputMask
        mask="+9 (999) 999-99-99"
        value={value ? value.toString() : ''}
        onChange={onChange}
      >
        {() => <TextField label={label} error={!!error} helperText={error} variant="outlined" />}
      </InputMask>
    </div>
  ) : (
    <div>{phone}</div>
  );
};

export { PhoneField };
