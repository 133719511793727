import { IEntityFetchFunction } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const fetchNotification: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const limit = count ? (page + 1) * count : 25;
  const orderBy = sortDir.toUpperCase();
  // noinspection TypeScriptValidateTypes
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || {};

  // fetching Notification
  const response = await axios.get(url, {
    params: {
      offset,
      limit,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    headers: { authorization: token },
  });

  const list = response?.data?.data || [];

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};
