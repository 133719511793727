import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useConfig } from 'icerockdev-admin-toolkit';
import { AppBar } from '@material-ui/core';
import styles from './styles.module.scss';
import { SuperAdminAccount } from '~/application/containers/auth/SuperAdminAccount';

interface IProps {}

const SuperAdminLayout: FC<IProps> = observer(({ children }) => {
  const config = useConfig();

  return (
    <div className={classNames(styles.layout, 'super-admin-layout')}>
      <AppBar
        className={classNames(styles.appbar, 'super-admin-layout__appbar')}
        position="relative"
      >
        {!!config.auth && (
          <div className={classNames(styles.accountwrap, 'super-admin-layout__account-wrap')}>
            <SuperAdminAccount />
          </div>
        )}
      </AppBar>

      <div className={classNames(styles.content, 'super-admin-layout__content')}>{children}</div>
    </div>
  );
});

export { SuperAdminLayout };
