import { IEntityField } from 'icerockdev-admin-toolkit';
import moment from 'moment';
import { minMaxLength, isEmail, regexp } from '~/utils/validators';
import {
  USER_STATUS_NAMES,
  SUBSCRIPTION_TYPE_NAMES,
  SUBSCRIPTION_STATUS_NAMES,
  MAX_NAME_LENGTH,
  MAX_WEBSITE_LENGTH,
  MIN_STRING_LENGTH,
  PHONE_NUMBER_LENGTH,
  ONLY_CYRILLIC_SYMBOLS,
  WEBSITE_SYMBOLS,
} from '~/pages/user/constants';
import { UserBlockButton } from '~/pages/user/components/UserBlockButton';
import { UserNameField } from '~/pages/user/components/UserNameField';
import { SubscriptionTimeField } from './components/SubscriptionTimeField';
import { SubscriptionStatusField } from '~/pages/user/components/SubscriptionStatusField';
import { SubscriptionStartTimeField } from '~/pages/user/components/SubscriptionStartTimeField';
import { SubscriptionEndTimeField } from '~/pages/user/components/SubscriptionEndTimeField';
import { PhoneField } from '~/application/modules/PhoneField';

export const USER_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
    filterable: true,
    required: true,
    hideInList: false,
  },
  {
    type: 'custom',
    name: 'name',
    label: 'ФИО',
    title: true,
    hideInEdit: true,
    hideInCreate: true,
    filterable: true,
    component: UserNameField,
    hideInList: false,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'firstName',
    label: 'Имя',
    required: true,
    hideInList: true,
    hideInView: true,
    validator:
      minMaxLength(
        MIN_STRING_LENGTH,
        MAX_NAME_LENGTH
      )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_NAME_LENGTH} символов`) &&
      regexp(ONLY_CYRILLIC_SYMBOLS)('Имя должно содержать только символы кириллицы'),
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Фамилия',
    required: true,
    hideInList: true,
    hideInView: true,
    validator:
      minMaxLength(
        MIN_STRING_LENGTH,
        MAX_NAME_LENGTH
      )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_NAME_LENGTH} символов`) &&
      regexp(ONLY_CYRILLIC_SYMBOLS)('Фамилия должна содержать только символы кириллицы'),
  },
  {
    type: 'string',
    name: 'middleName',
    label: 'Отчество',
    required: true,
    hideInList: true,
    hideInView: true,
    validator:
      minMaxLength(
        MIN_STRING_LENGTH,
        MAX_NAME_LENGTH
      )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_NAME_LENGTH} символов`) &&
      regexp(ONLY_CYRILLIC_SYMBOLS)('Отчество должно содержать только символы кириллицы'),
  },
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    required: true,
    hideInEdit: true,
    filterable: true,
    validator: isEmail('Невалидный адрес электронной почты'),
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Телефон',
    required: true,
    hideInEdit: true,
    component: PhoneField,
    filterable: true,
    validator: minMaxLength(PHONE_NUMBER_LENGTH, PHONE_NUMBER_LENGTH)('Должно быть 11 символов'),
  },
  {
    type: 'string',
    name: 'website',
    label: 'Адрес сайта',
    hideInList: true,
    required: true,
    hideInView: false,
    validator:
      minMaxLength(
        MIN_STRING_LENGTH,
        MAX_WEBSITE_LENGTH
      )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_WEBSITE_LENGTH} символов`) &&
      regexp(WEBSITE_SYMBOLS)('Обнаружены недопустимые символы'),
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус пользователя',
    options: USER_STATUS_NAMES,
    hideInCreate: true,
    hideInEdit: true,
    sortable: true,
  },
  {
    type: 'date',
    name: 'subscriptionStartTime',
    label: 'Дата начала текущей или последней подписки',
    required: true,
    hideInList: true,
    hideInEdit: true,
    hideInView: true,
  },
  {
    type: 'date',
    name: 'subscriptionEndTime',
    label: 'Дата окончания текущей или последней подписки',
    required: true,
    hideInList: true,
    hideInView: true,
    hideInEdit: true,
    validator(val, entity) {
      const endSubscription = moment(val).add(1, 'day');
      const startSubscription = moment(entity.editorData.subscriptionStartTime);
      const monthsSubscription = endSubscription.diff(startSubscription, 'months') % 12;
      const yearSubscription = endSubscription.diff(startSubscription, 'years');
      const daySubscription = endSubscription.diff(startSubscription, 'days');

      return monthsSubscription === 6 ||
        (monthsSubscription === 0 && yearSubscription === 1) ||
        (monthsSubscription === 11 && daySubscription === 364) ||
        daySubscription === 365
        ? ''
        : 'Подписка может быть только 6 или 12 месяцев.';
    },
  },
  {
    type: 'custom',
    name: 'subscriptionStartTimeShow',
    label: 'Дата начала текущей или последней подписки',
    hideInCreate: true,
    hideInEdit: true,
    component: SubscriptionStartTimeField,
    hideInList: true,
    hideInView: false,
  },
  {
    type: 'custom',
    name: 'subscriptionEndTimeShow',
    label: 'Дата окончания текущей или последней подписки',
    hideInCreate: true,
    hideInEdit: true,
    component: SubscriptionEndTimeField,
    hideInList: true,
    hideInView: false,
  },
  {
    type: 'select',
    name: 'subscriptionStatus',
    label: 'Статус подписки',
    options: SUBSCRIPTION_STATUS_NAMES,
    hideInCreate: true,
    hideInEdit: true,
    filterable: true,
    component: SubscriptionStatusField,
  },
  {
    type: 'select',
    name: 'subscriptionType',
    label: 'Тип подписки',
    options: SUBSCRIPTION_TYPE_NAMES,
    hideInCreate: true,
    hideInEdit: true,
    filterable: true,
    hideInList: false,
  },
  {
    type: 'custom',
    name: 'subscriptionTime',
    label: 'Начало / окончание текущей или последней подписки',
    hideInCreate: true,
    hideInEdit: true,
    component: SubscriptionTimeField,
    hideInList: true,
    hideInView: true,
  },

  // Buttons
  {
    type: 'custom',
    name: ' ',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    component: UserBlockButton,
    hideInList: false,
    hideInView: true,
  },
];

export const SUBSCRIPTION_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'appId',
    hideInList: false,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'number',
    name: 'adminId',
    hideInList: false,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'date',
    name: 'startAt',
    label: 'Дата начала текущей или последней подписки',
    required: true,
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'date',
    name: 'expiredAt',
    label: 'Дата окончания текущей или последней подписки',
    required: true,
    hideInList: true,
    hideInEdit: true,
    validator(val, entity) {
      return moment(entity.editorData.subscriptionStartTime).isBefore(val)
        ? ''
        : 'Дата окончания текущей или последней подписки должна быть больше Дата начала текущей или последней подписки';
    },
  },
];
