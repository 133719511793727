import {
  JWTAuthProvider,
  AuthProvider,
  IJWTAuthProviderProps,
  WithTokenFunction,
  EMPTY_USER,
  UNAUTHORIZED,
} from 'icerockdev-admin-toolkit';
import { action, flow, observable, reaction } from 'mobx';
import { CancellablePromise } from 'mobx/lib/api/flow';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { AxiosResponse } from 'axios';
import { ResetPassword } from './ResetPassword';
import {
  authRequestFn,
  tokenRefreshFn,
  authLogoutFn,
  authPasswordChangeFn,
  authPasswordRestoreFn,
  authPasswordConfirmFn,
  authSignUpConfirmFn,
  authSignUpFn,
} from './api';
import { UserRole } from '~/pages/user/constants';
// eslint-disable-next-line import/extensions
import splash from '~/assets/splash.png';
import { AuthVerticalLayout } from '~/application/layouts/AuthVerticalLayout';
import NotificationError from '~/utils/notificationError';
import { SuperAdminLayout } from '~/application/layouts/SuperAdminLayout';
import { AppAdminLayout } from '~/application/layouts/AppAdminLayout';
import { AuthRouter } from '~/auth/AuthRouter';
import { SignUp } from '~/auth/SignUp';
import { SignIn } from '~/auth/SignIn';

export type ExtendAxiosResponse = AxiosResponse & { error?: string | undefined };

export type AuthPasswordRestoreFn = (email: string) => Promise<ExtendAxiosResponse>;

export type AuthPasswordConfirmFn = (token: string) => Promise<ExtendAxiosResponse>;

export type AuthPasswordChangeFn = (
  token: string,
  password: string,
  passwordRepeat?: string
) => Promise<ExtendAxiosResponse>;

export type AuthSignUpConfirmFn = (token: string) => Promise<ExtendAxiosResponse>;

export type CustomJWTAuthProviderProps = IJWTAuthProviderProps & {
  authPasswRestoreFn: AuthPasswordRestoreFn;
  authPasswordConfirmFn: AuthPasswordConfirmFn;
  authPasswUpdateFn: AuthPasswordChangeFn;
  authSignUpConfirmFn: AuthSignUpConfirmFn;
};

const EMPTY_TOKENS = {
  access: '',
  refresh: '',
};

export class CustomJWTAuthProvider extends JWTAuthProvider {
  @observable changePasswordToken?: string;

  @observable authPasswordConfirmFn?: AuthPasswordConfirmFn;

  @observable signUpToken?: string;

  @observable authSignUpConfirmFn?: AuthSignUpConfirmFn;

  constructor(fields?: Partial<CustomJWTAuthProviderProps>) {
    super(fields);
    reaction(
      () => this.user,
      () => this.refreshLayout()
    );
  }

  @action
  refreshLayout = async () => {
    if (this.parent) {
      this.parent.layout =
        this.getUserRole(this) === UserRole.SuperAdmin ? SuperAdminLayout : AppAdminLayout;
    }
  };

  @action
  withToken: WithTokenFunction = async (req: any, args: any) =>
    req({
      ...args,
      token: `Bearer ${this.tokens.access}`,
    })
      .then((result: any) => {
        const user = localStorage.getItem('user');
        if (user === '{}') {
          this.user = EMPTY_USER;
          this.tokens = EMPTY_TOKENS;
        }
        if (result?.error === UNAUTHORIZED) {
          try {
            const tokens = this.tokenRefreshInstance;
            this.tokens = {
              access: tokens.access || '',
              refresh: tokens.refresh,
            };
          } catch (err) {
            this.user = EMPTY_USER;
            this.tokens = EMPTY_TOKENS;
            throw err;
          }
        }
        return result;
      })
      .catch(async (e: Error) => {
        if (e?.message.indexOf('401') !== -1 || e?.message.indexOf('400') !== -1) {
          this.user = EMPTY_USER;
          this.tokens = EMPTY_TOKENS;
          return e;
        }
        if (!this.tokenRefreshFn) {
          console.warn('tokenRefreshFn not specified');
          throw new Error(e.message);
        }
        // If there's other updater, wait for it
        if (!this.tokenRefreshInstance) {
          this.tokenRefreshInstance = flow(function* (this: JWTAuthProvider) {
            if (!this.tokenRefreshFn) return { access: '', refresh: '' };

            return yield this.tokenRefreshFn(this.tokens.refresh);
          }).bind(this)();

          try {
            const tokens = await this.tokenRefreshInstance;
            this.tokens = {
              access: tokens.access || '',
              refresh: tokens.refresh,
            };
          } catch (err) {
            this.user = EMPTY_USER;
            this.tokens = EMPTY_TOKENS;
            throw err;
          }

          this.tokenRefreshInstance = null;
        } else {
          await this.tokenRefreshInstance;
        }

        if (this.tokens.access && this.tokens.refresh) {
          return req({
            ...args,
            token: `Bearer ${this.tokens.access}`,
          });
        }

        this.user = EMPTY_USER;
        this.tokens = EMPTY_TOKENS;

        return e;
      });

  @action
  sendAuthPasswRestore = (email: string) => {
    this.sendAuthPasswRestoreCancel();

    this.sendAuthPasswRestoreInstance = flow(function* sendAuthPasswRestore(this: AuthProvider) {
      if (!this.authPasswRestoreFn) return;

      this.isLoading = true;

      try {
        yield this.authPasswRestoreFn(email);

        this.parent?.notifications.showSuccess(
          i18n.t(
            'messages:A message has been sent to the email linked to this login with a link to reset the password'
          )
        );
        // noinspection TypeScriptValidateTypes
        this.parent?.history.push('/');
      } catch (e) {
        this.error = e.message;
        this.parent?.notifications.showError(e.message);
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  sendAuthPasswordConfirmInstance?: CancellablePromise<any>;

  @action
  sendAuthPasswordConfirm = (token: string) => {
    this.sendAuthPasswordConfirmCancel();

    this.sendAuthPasswordConfirmInstance = flow(function* sendAuthPasswordConfirm(
      this: CustomJWTAuthProvider
    ) {
      if (!this.authPasswordConfirmFn) return;

      this.isLoading = true;

      try {
        const response = yield this.authPasswordConfirmFn(token);
        this.changePasswordToken = response?.data?.data.confirmationToken;
      } catch (e) {
        this.error = e.message;
        this.parent?.notifications.showError(e.message);
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  sendAuthPasswordConfirmCancel = () => {
    if (this.sendAuthPasswordConfirmInstance && this.sendAuthPasswordConfirmInstance.cancel) {
      try {
        this.sendAuthPasswordConfirmInstance.cancel();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  };

  @action
  sendAuthPasswUpdate = (token: string, password: string) => {
    this.sendAuthPasswRestoreCancel();

    this.sendAuthPasswUpdateInstance = flow(function* sendAuthPasswUpdate(this: AuthProvider) {
      if (!this.authPasswUpdateFn) return;

      this.isLoading = true;

      try {
        if (!token.trim()) {
          throw new NotificationError(i18n.t('Error'), i18n.t('messages:Token invalid or empty'));
        }

        if (this.passwordValidator && this.passwordValidator(password)) {
          throw new NotificationError(i18n.t('Error'), this.passwordValidator(password) ?? '');
        }

        yield this.authPasswUpdateFn(token, password).catch(() => null);

        this.parent?.notifications.showSuccess(i18n.t('messages:Password change was successful'));
        // noinspection TypeScriptValidateTypes
        this.parent?.history.push('/');
      } catch (e) {
        this.error = e.message;
        this.parent?.notifications.showError(e.message);
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  sendAuthSignupConfirmInstance?: CancellablePromise<any>;

  @action
  sendAuthSignupConfirm = (token: string) => {
    this.sendAuthSignupConfirmCancel();

    this.sendAuthPasswordConfirmInstance = flow(function* sendAuthSignupConfirm(
      this: CustomJWTAuthProvider
    ) {
      if (!this.authSignUpConfirmFn) return;

      this.isLoading = true;

      try {
        const response = yield this.authSignUpConfirmFn(token);
        this.signUpToken = response?.data?.data.confirmationToken;
      } catch (e) {
        this.error = e.message;
        this.parent?.notifications.showError(e.message);
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  sendAuthSignupConfirmCancel = () => {
    if (this.sendAuthSignupConfirmInstance && this.sendAuthSignupConfirmInstance.cancel) {
      try {
        this.sendAuthSignupConfirmInstance.cancel();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  };

  @action
  sendAuthSignup = (data: any) => {
    flow(function* sendAuthSignup(this: CustomJWTAuthProvider) {
      if (!this.authSignupFn) return;

      this.isLoading = true;

      try {
        yield this.authSignupFn(data);
        this.parent?.notifications.showSuccess(i18n.t('messages:Now you can log in'));
        this.parent?.history.push('/');
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.toString());
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  sendAuthRequest = (email: string, password: string) => {
    this.sendAuthRequestCancel();

    this.sendAuthRequestInstance = flow(function* sendAuthRequest(this: JWTAuthProvider) {
      if (!this.authRequestFn) return;

      this.isLoading = true;

      try {
        const response = yield this.authRequestFn(email, password);

        if (!response || response.error) {
          throw new Error(response?.error);
        }

        this.parent?.notifications.hideNotification();
        this.user = response.user;
        this.tokens = response.tokens;
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.toString());
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  sendAuthRequestCancel = () => {
    if (this.sendAuthRequestInstance && this.sendAuthRequestInstance.cancel) {
      this.sendAuthRequestInstance.cancel();
    }
  };
}

const authProvider = new CustomJWTAuthProvider({
  router: AuthRouter,
  layout: AuthVerticalLayout,
  splash,
  authRequestFn,
  tokenRefreshFn,
  authLogoutFn,
  authPasswRestoreFn: authPasswordRestoreFn,
  authPasswordConfirmFn,
  authPasswUpdateFn: authPasswordChangeFn,
  authSignUpConfirmFn,
  authSignupFn: authSignUpFn,
  signUp: SignUp,
  signIn: SignIn,
  resetPassword: ResetPassword,
  loginLabel: 'Email',
  getUserName: ({ user }) =>
    `${user.lastName} ${user.firstName?.charAt(0)} ${user.middleName?.charAt(0) || ''}`,
  getUserRoleTitle: (auth) => auth.user.email ?? '',
  getUserRole: (auth) => auth.user.role ?? UserRole.AppAdmin,
});

export default authProvider;
