import React, { FC } from 'react';
import { useSlate } from 'slate-react';
import { IconButton } from '@material-ui/core';
import { isElementActive, toggleElement } from '~/pages/news/slate/editor';
import { EditorElements } from '~/pages/news/slate/types';

export type LeafButtonProps = {
  type: EditorElements;
  children?: React.ReactNode;
};

const ElementButton: FC<LeafButtonProps> = ({ type, children }) => {
  const editor = useSlate();
  return (
    <IconButton
      color={isElementActive(editor, type) ? 'inherit' : 'default'}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleElement(editor, type);
      }}
    >
      {children}
    </IconButton>
  );
};

export { ElementButton };
