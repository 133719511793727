import React from 'react';
import { Page } from 'icerockdev-admin-toolkit';
import { computed, action } from 'mobx';
import { observer, Provider } from 'mobx-react';
import Steps from '../Steps';
import {
  getApplicationApi,
  putApplicationApi,
  getSetupApi,
  getVersionApi,
  addAppFileApi,
} from '../../api';
import { API_URLS } from '~/utils/constants';

export class CustomizationPage extends Page {
  @action
  getApplication = async () => {
    if (!this.parent?.auth?.withToken) return null;

    const result = await this.parent.auth.withToken(getApplicationApi, {
      url: API_URLS.customization.get,
    });

    return result.data;
  };

  @action
  putApplication = async (data) => {
    if (!this.parent?.auth?.withToken) return;
    await this.parent.auth.withToken(putApplicationApi, {
      url: API_URLS.customization.get,
      data,
    });
  };

  @action
  addAppFile = async (file) => {
    if (!this.parent?.auth?.withToken) return;

    const result = await this.parent.auth.withToken(addAppFileApi, {
      url: API_URLS.customization.file,
      data: file,
    });

    // eslint-disable-next-line consistent-return
    return result;
  };

  @action
  getVersion = async () => {
    if (!this.parent?.auth?.withToken) return null;

    const result = await this.parent.auth.withToken(getVersionApi, {
      url: API_URLS.customization.getVersion,
    });

    return result.data;
  };

  @action
  getSetup = async () => {
    if (!this.parent?.auth?.withToken) return null;

    const result = await this.parent.auth.withToken(getSetupApi, {
      url: API_URLS.customization.getSetup,
    });

    return result.data;
  };

  @computed
  get output() {
    return observer(() => (
      <Provider entity={this}>
        <Steps
          getApplication={this.getApplication}
          putApplication={this.putApplication}
          getSetup={this.getSetup}
          getVersion={this.getVersion}
          addAppFile={this.addAppFile}
        />
      </Provider>
    ));
  }
}
