import { Config } from 'icerockdev-admin-toolkit';
import auth from '../auth';
import user from '../pages/user';
import feed from '../pages/feed';
import { SuperAdminLayout } from '~/application/layouts/SuperAdminLayout';
import Account from '~/pages/account';
import news from '~/pages/news';
import notification from '~/pages/notification';
import customization from '~/pages/customization';
import theme from './theme';
import { UserRole } from '~/pages/user/constants';
import { AppAdminLayout } from '~/application/layouts/AppAdminLayout';

export default new Config({
  host: process.env.REACT_APP_BASE_URL,
  auth,
  pages: [user, feed, Account, notification, news, customization],
  layout: auth.getUserRole(auth) === UserRole.SuperAdmin ? SuperAdminLayout : AppAdminLayout,
  i18nDefaultLanguage: 'ru',
  theme,
});
