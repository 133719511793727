import React, { FC, ReactNode, useMemo, useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { NewsEntity } from '~/pages/news/components/NewsEntity';
import { FeedEntity } from '~/pages/feed/components/FeedEntity';

interface IProps {
  data: Record<string, any> | undefined;
  children: ReactNode;
  entity: NewsEntity | FeedEntity;
}

export const ModalNotifications: FC<IProps> = ({ data, children, entity }) => {
  const [open, setOpen] = useState(false);
  const [stateInput, setStateInput] = useState<Record<string, string>>({});
  const { title, body } = stateInput;
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const onChange = (e) => {
    const { name, value } = e.target;
    setStateInput((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateFields = useCallback(() => {
    const errorsFields = {
      title: !title || title.length < 1 || title.length > 50,
      body: !body || body.length < 1 || body.length > 120,
    };

    setErrors(errorsFields);

    return !Object.values(errorsFields).some((val) => val);
  }, [title, body, setErrors]);

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!validateFields()) return;

      stateInput.newsId = data?.id;
      stateInput.appId = data?.appId;
      if (title && body) {
        entity.addNotification(stateInput);
        setOpen(false);
      }
    },
    [entity, stateInput, data?.id]
  );
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const modalButton = useMemo(() => {
    if (React.isValidElement(children)) {
      // noinspection TypeScriptValidateTypes
      return React.cloneElement(children, {
        onClick: (event) => {
          event.stopPropagation();
          setOpen(true);
        },
      });
    }
    return null;
  }, [children]);

  return (
    <div>
      {modalButton}
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={styles.modal_title} id="form-dialog-title">
          {t('Create a notification')}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Заголовок"
            type="text"
            fullWidth
            value={title}
            onChange={onChange}
            variant="outlined"
            error={errors.title}
            helperText={
              errors.title ? t('messages:Title must be at least 1 and maximum 50 characters') : ''
            }
          />
          <TextField
            margin="dense"
            name="body"
            label="Текст уведомления"
            type="text"
            fullWidth
            multiline
            value={body}
            onChange={onChange}
            rows={4}
            variant="outlined"
            error={errors.body}
            helperText={
              errors.body
                ? t('messages:Notification text must be at least 1 and maximum 120 characters')
                : ''
            }
          />
        </DialogContent>
        <DialogActions>
          <Button className={styles.button__disagree} onClick={handleClose} color="primary">
            {t('buttons:Cancel')}
          </Button>
          <Button className={styles.button__agree} onClick={onClick} color="primary">
            {t('buttons:Send')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
