/* eslint-disable jsx-a11y/anchor-is-valid,max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
/* eslint-disable consistent-return */
import React, { ChangeEvent, useCallback, useState, FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Button from '@material-ui/core/Button';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import classNames from 'classnames';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Textarea } from './components/Textarea';
import formInputs from './constant';
import { TextInput } from './components/TextInput';
import styles from './styles.module.scss';
import Firebase from './access/image/Firebase.png';
import FirebaseCreate1 from './access/image/FirebaseCreate1.png';
import FirebaseCreate2 from './access/image/FirebaseCreate2.png';
import FirebaseCreate3 from './access/image/FirebaseCreate3.png';
import FirebaseCreate4 from './access/image/FirebaseCreate4.png';
import FirebaseKey1 from './access/image/FirebaseKey1.png';
import FirebaseKey2 from './access/image/FirebaseKey2.png';
import ico1 from './access/image/ico1.png';
import ico2 from './access/image/ico2.png';
import settingLogo from './access/image/settingLogo.png';
import settingLogoPin from './access/image/settingLogoPin.png';
import Logo1 from './access/image/Logo1.png';
import Logo2 from './access/image/Logo2.png';
// import FirebaseSettings from './access/image/FirebaseSettings.png';
// import FireBaseServerKey from './access/image/FireBaseServerKey.png';
// import ServiceAccountKey from './access/image/ServiceAccountKey.png';
// import KeysTab from './access/image/KeysTab.png';
// import KeysTabStep1 from './access/image/KeysTabStep1.png';
// import KeysTabStep2 from './access/image/KeysTabStep2.png';
// import KeysTabStep3 from './access/image/KeysTabStep3.png';
// import settingIcon from './access/image/settingIcon.png';
// import IconiOs from './access/image/IconiOS.png';

interface IProps {
  getApplication: any;
  putApplication: any;
  addAppFile: any;
}

const ApplicationSetup: FC<IProps> = observer(({ putApplication, getApplication, addAppFile }) => {
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [Logo, setLogo] = useState<string>();
  const [Icon, setIcon] = useState<string>();
  const [uploadedEarlierLogo, setuploadedEarlierLogo] = useState<boolean>(false);
  const [uploadedEarlierIcon, setuploadedEarlierIcon] = useState<boolean>(false);
  const [LogoNameFile, setNameLogoFile] = useState<string>('');
  const [IconNameFile, setNameIconFile] = useState<string>('');
  const [inputsState, setInputsState] = useState<Record<string, string>>({});
  const {
    name,
    packageName,
    fcmServerKey,
    privacyPolicyUrl,
    appStoreSharedKey,
    subscribe3MonthsId,
    subscribe6MonthsId,
    subscribe12MonthsId,
    playMarketKey,
  } = inputsState;
  const [color, setColor] = useState(createColor('#4253AF'));
  const handleChange = (newValue: Color) => {
    if (newValue.hex) {
      setColor(newValue);
      inputsState.color = newValue.hex;
    } else {
      setColor(createColor(newValue));
      inputsState.color = createColor(newValue).hex;
    }
  };

  const validateFields = useCallback(() => {
    const hex = inputsState.color;
    const isErrors = {
      name: !name || name.length <= 2,
      packageName: !packageName || packageName.length <= 2,
      fcmServerKey: !fcmServerKey || fcmServerKey.length <= 2,
      appStoreSharedKey: !appStoreSharedKey || appStoreSharedKey.length <= 2,
      subscribe3MonthsId: !subscribe3MonthsId || subscribe3MonthsId.length <= 2,
      subscribe6MonthsId: !subscribe6MonthsId || subscribe6MonthsId.length <= 2,
      subscribe12MonthsId: !subscribe12MonthsId || subscribe12MonthsId.length <= 2,
      playMarketKey: !playMarketKey || playMarketKey.length <= 2,
      hex: !hex || hex.length <= 2 || !color.value,
      privacyPolicyUrl: !privacyPolicyUrl || privacyPolicyUrl.length <= 2,
      Icon: !Icon,
      Logo: !Logo,
    };

    setErrors(isErrors);

    return !Object.values(isErrors).some((val) => val);
  }, [
    name,
    packageName,
    fcmServerKey,
    appStoreSharedKey,
    subscribe3MonthsId,
    subscribe6MonthsId,
    subscribe12MonthsId,
    playMarketKey,
    privacyPolicyUrl,
    color,
    Logo,
    Icon,
    setErrors,
  ]);

  const history = useHistory();

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const nameInput = e.target.name;
    const { value } = e.target;

    if (errors[name]) setErrors((prevState) => ({ ...prevState, [nameInput]: false }));

    setInputsState((prevState) => ({ ...prevState, [nameInput]: value }));
  };

  const onChangeIconApp = useCallback(async (e) => {
    const DATA = new FormData();
    DATA.append('file', e.target.files[0]);
    setNameIconFile(e.target.files[0].name);
    const resultIconApp = await addAppFile(DATA);
    setIcon(resultIconApp.data.data);
    setuploadedEarlierIcon(false);
  }, []);

  const onChangeIconLogo = useCallback(async (e) => {
    const DATA = new FormData();
    setNameLogoFile(e.target.files[0].name);
    DATA.append('file', e.target.files[0]);
    const resultIconLogo = await addAppFile(DATA);
    setLogo(resultIconLogo.data.data);
    setuploadedEarlierLogo(false);
  }, []);

  useEffect(() => {
    (async () => {
      const result = await getApplication();
      setColor(createColor(`#${result.color}`));
      const obj = {};
      [
        'name',
        'fcmServerKey',
        'appStoreSharedKey',
        'packageName',
        'privacyPolicyUrl',
        'subscribe3MonthsId',
        'subscribe6MonthsId',
        'subscribe12MonthsId',
        'playMarketKey',
      ].forEach((key) => {
        obj[key] = result[key];
      });
      inputsState.color = result.color;
      if (result.icon) {
        setLogo(result.icon);
        setuploadedEarlierIcon(true);
      }
      if (result.logo) {
        setIcon(result.logo);
        setuploadedEarlierLogo(true);
      }

      setInputsState((prevState) => ({ ...prevState, ...obj }));
    })();
  }, []);

  const handleSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (event) => {
      event.preventDefault();
      if (!validateFields()) return;

      try {
        await putApplication({ ...inputsState, icon: Icon, logo: Logo });
        history.push('/customization/setup');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [validateFields, setInputsState, Icon, Logo]
  );

  const [isActiveInstructionName, setActiveInstructionName] = useState(false);
  const [isActiveInstructionPackageName, setActiveInstructionPackageName] = useState(false);
  const [isActiveInstructionPrivacyPolicyUrl, setActiveInstructionPrivacyPolicyUrl] =
    useState(false);
  const [isActiveInstructionFcmServerKey, setActiveInstructionFcmServerKey] = useState(false);
  const [isActiveInstructionSubscribe, setActiveInstructionSubscribe] = useState(false);
  const [isActiveInstructionAppStoreSharedKey, setActiveInstructionAppStoreSharedKey] =
    useState(false);
  const [isActiveInstructionPlayMarketKey, setActiveInstructionPlayMarketKey] = useState(false);
  const [isActiveInstructionIconLogo, setActiveInstructionIconLogo] = useState(false);
  const [isActiveInstructionIconApp, setActiveInstructionIconApp] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setActiveInstructionName(false);
    setActiveInstructionPackageName(false);
    setActiveInstructionPrivacyPolicyUrl(false);
    setActiveInstructionFcmServerKey(false);
    setActiveInstructionAppStoreSharedKey(false);
    setActiveInstructionSubscribe(false);
    setActiveInstructionPlayMarketKey(false);
    setActiveInstructionIconLogo(false);
    setActiveInstructionIconApp(false);
    setOpen(false);
  };
  const onClick = (caseName) => {
    setOpen(true);
    switch (caseName) {
      case 'name':
        return setActiveInstructionName(true);
      case 'packageName':
        return setActiveInstructionPackageName(true);
      case 'privacyPolicyUrl':
        return setActiveInstructionPrivacyPolicyUrl(true);
      case 'fcmServerKey':
        return setActiveInstructionFcmServerKey(true);
      case 'appStoreSharedKey':
        return setActiveInstructionAppStoreSharedKey(true);
      case 'subscribe':
        return setActiveInstructionSubscribe(true);
      case 'playMarketKey':
        return setActiveInstructionPlayMarketKey(true);
      case 'iconApp':
        return setActiveInstructionIconApp(true);
      case 'iconLogo':
        return setActiveInstructionIconLogo(true);
      default:
        break;
    }
  };
  return (
    <div className={styles.wraper}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        {isActiveInstructionName ? (
          <DialogTitle id="scroll-dialog-title">Название приложения</DialogTitle>
        ) : (
          <></>
        )}
        {isActiveInstructionPackageName ? (
          <DialogTitle id="scroll-dialog-title">packageName</DialogTitle>
        ) : (
          <></>
        )}
        {isActiveInstructionPrivacyPolicyUrl ? (
          <DialogTitle id="scroll-dialog-title">privacyPolicyUrl</DialogTitle>
        ) : (
          <></>
        )}
        {isActiveInstructionFcmServerKey ? (
          <DialogTitle id="scroll-dialog-title">fcmServerKey</DialogTitle>
        ) : (
          <></>
        )}
        {isActiveInstructionSubscribe ? (
          <DialogTitle id="scroll-dialog-title">subscribe</DialogTitle>
        ) : (
          <></>
        )}
        {isActiveInstructionAppStoreSharedKey ? (
          <DialogTitle id="scroll-dialog-title">appStoreSharedKey</DialogTitle>
        ) : (
          <></>
        )}
        {isActiveInstructionPlayMarketKey ? (
          <DialogTitle id="scroll-dialog-title">playMarketKey</DialogTitle>
        ) : (
          <></>
        )}
        {isActiveInstructionIconApp ? (
          <DialogTitle id="scroll-dialog-title">Иконка приложения</DialogTitle>
        ) : (
          <></>
        )}
        {isActiveInstructionIconLogo ? (
          <DialogTitle id="scroll-dialog-title">Логотип приложения</DialogTitle>
        ) : (
          <></>
        )}
        <DialogContent>
          <DialogContentText>
            {isActiveInstructionName ? (
              <p className={styles.title}>
                Основное название приложения, которое будет отображаться на устройстве после
                установки.
              </p>
            ) : (
              <></>
            )}
            {isActiveInstructionPackageName ? (
              <div>
                <p>
                  Данное поле заполняем из п. 2.2
                  {' '}
                  <a href="https://skyur.app/apple/">
                    раздела Регистрация и настройка учетной записи в Apple
                  </a>
                  , которую мы уже проходили.
                </p>
              </div>
            ) : (
              <></>
            )}
            {isActiveInstructionPrivacyPolicyUrl ? (
              <div>
                <p>
                  Здесь мы указываем политику конфиденциальности вашего сайта. Она должна быть
                  размещена на вашем домене. Если у вас ее нет, то вы можете сгенерировать ее в
                  {' '}
                  <a href="https://tilda.cc/ru/privacy-generator/">бесплатном сервисе</a>
                  .
                </p>
              </div>
            ) : (
              <></>
            )}
            {isActiveInstructionFcmServerKey ? (
              <div>
                <h3>Получение Server key для отправки пушей</h3>
                <p>
                  Чтобы получить server push token для firebase cloud messaging, необходимо сначала
                  создать проект в консоли Firebase.
                </p>
                <p>
                  1. Переходим по
                  {' '}
                  <a href="https://console.firebase.google.com">ссылке</a>
                  , логинимся через свой аккаунт Google и добавляем новый проект.
                  <img src={Firebase} alt="" />
                </p>
                <p>
                  2. Здесь мы выбираем из выпадающего списка уже созданный в предыдущем разделе
                  {' '}
                  <a href="https://skyur.app/googleplay/">
                    Регистрация и настройка аккаунта в Google Play
                  </a>
                  {' '}
                  проект.
                </p>
                <img src={FirebaseCreate1} alt="" />
                <img src={FirebaseCreate2} alt="" />
                <img src={FirebaseCreate3} alt="" />
                <img src={FirebaseCreate4} alt="" />
                <p>
                  3. После создания проекта будет доступен главный экран консоли. Переходим в
                  Settings → Project settings → Вкладка cloud messaging. И тут копируем ключ в наше
                  поле. Это тот самый ключ.
                </p>
                <img src={FirebaseKey1} alt="" />
                <img src={FirebaseKey2} alt="" />
              </div>
            ) : (
              <></>
            )}
            {isActiveInstructionSubscribe ? (
              <div>
                <p>
                  В разделе
                  {' '}
                  <a href="https://skyur.app/apple/">
                    Регистрация и настройка учетной записи в Apple
                  </a>
                  {' '}
                  мы уже создавали подписки и указывали id для них в пункте 3.6. Необходимо
                  скопировать их id в эти поля.
                </p>
              </div>
            ) : (
              <></>
            )}
            {isActiveInstructionAppStoreSharedKey ? (
              <div>
                <p>
                  В это поле копируем ключ из пункта 3.13 раздела
                  {' '}
                  <a href="https://skyur.app/apple/">
                    Регистрация и настройка учетной записи в Apple
                  </a>
                </p>
                <img src={appStoreSharedKey} alt="" />
              </div>
            ) : (
              <></>
            )}
            {isActiveInstructionPlayMarketKey ? (
              <div>
                <p>
                  В разделе
                  {' '}
                  <a href="https://skyur.app/googleplay/">
                    Регистрация и настройка аккаунта в Google Play
                  </a>
                  {' '}
                  в пункте 4.8 мы создали и скачали на компьютер ключ в формате .json. Нужно открыть
                  этот файл в текстовом редакторе и скопировать оттуда весь текст в данное поле. Это
                  и есть тот самый ключ.
                </p>
              </div>
            ) : (
              <></>
            )}
            {isActiveInstructionIconApp ? (
              <div>
                <h3>Добавление изображений</h3>
                <p>
                  Иконка приложения – это та самая иконка, которая будет отображаться на устройстве
                  пользователей после установки приложения.
                </p>
                <p>
                  1. Переходим в сервис
                  {' '}
                  <a href="https://appicon.co/">App Icon Generator</a>
                  ,
                  загружаем иконку, выбираем iPhone и Android и генерируем иконку.
                </p>
                <img src={ico1} alt="" />
                <p>2. Полученный архив загружаем к нам.</p>
                <img src={ico2} alt="" />
              </div>
            ) : (
              <></>
            )}
            {isActiveInstructionIconLogo ? (
              <div>
                <p>
                  Логотип приложения – этот ваш логотип внутри приложения. Он будет показываться в
                  шапке приложения и при открытии приложения. Учтите, что логотип будет на белом
                  фоне. Темная тема у приложения будет позже.
                </p>
                <p>1. Необходимо сформировать ZIP архив с указанной структурой</p>
                <img src={settingLogo} alt="" />
                <p>
                  2. Для простоты скачайте уже
                  {' '}
                  <a download href="/assets/zip/logo.zip">
                    {' '}
                    готовый ZIP-архив
                  </a>
                  {' '}
                  с правильной структурой, разархивируйте, замените выделенные оранжевым цветом
                  логотипы, затем снова заархивируйте и загрузите в генератор.
                </p>
                <p>
                  2.1 Скачайте архив по
                  {' '}
                  <a download href="/assets/zip/logo.zip">
                    ссылке
                  </a>
                  .
                </p>
                {/* не хочет кидать пустрой комит просто с линеторм */}
                <p>
                  2.2. Замените логотип в папке android-app/src/main/res/drawable с названием
                  ic_logo.png на логотип вашей компании с тем же названием и размером 180х56.
                </p>
                <img src={settingLogoPin} alt="" />
                <p>
                  2.3. Замените 3 логотипа на логотипы вашей компании с теми же именами и размерами
                  в папке ios-app/src/Assets.xcassets/NavBar/companyLogo.imageset следующие
                  логотипы:
                  {' '}
                </p>
                <p>companyLogo@1x.png - 90х28</p>
                <p>companyLogo@2x.png- 180х56</p>
                <p>companyLogo@3x.png -240х84</p>
                <p>
                  2.4. Замените 3 логотипа на логотипы вашей компании с теми же именами и размерами
                  в папке ios-app/src/Assets.xcassets/splash_naked_science_logo.imageset следующие
                  логотипы:
                  {' '}
                </p>
                <p>splash_naked_science_logo@1x.png - 180х56</p>
                <p>splash_naked_science_logo@2x.png- 360х112</p>
                <p>splash_naked_science_logo@3x.png -540х168</p>
                <p>
                  2.5. После замены данных логотипов заархивируйте папку logo и полученный архив
                  загрузите на сайт.
                </p>
                <img src={Logo1} alt="" />
                <img src={Logo2} alt="" />
              </div>
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <h3 className={styles.title}>Генерация приложения</h3>
      <div className={styles.instruction_wrap}>
        <h3 className={styles.instruction_text}>Для начала работы вам нужно:</h3>
        <ol className={styles.instruction_list}>
          <li className={styles.instruction_text}>
            Операционная система MacOS – для работы с приложениями Apple, иначе никак
          </li>
          <li className={styles.instruction_text}>
            Открыть учетную запись разработчика в AppStore (
            <a href="https://skyur.app/apple/" target="_blank" rel="noreferrer">
              Инструкция
            </a>
            )
          </li>
          <li className={styles.instruction_text}>
            Открыть учетную запись разработчика в Google Play (
            <a href="https://skyur.app/googleplay/" target="_blank" rel="noreferrer">
              Инструкция
            </a>
            )
          </li>
        </ol>
        <h3 className={styles.instruction_text}>
          Выполните эти действия и вернитесь на этот экран для продолжения.
        </h3>
        <h3 className={styles.instruction_text}>
          Если возникли сложности с прохождением этих шагов обратитесь по
          {' '}
          <a href="mailto:support@skyur.app">support@skyur.app</a>
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        {formInputs.map(({ nameImput, label }) => (
          <div className={styles.input_btn}>
            <TextInput
              name={nameImput}
              value={inputsState[nameImput] ?? ''}
              handler={onChange}
              label={label}
              hasError={errors[nameImput]}
              key={nameImput}
            />
            <div className={styles.btn}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onClick(nameImput);
                }}
              >
                <HelpOutline />
              </Button>
            </div>
          </div>
        ))}
        <div className={styles.input_btn}>
          <TextInput
            name="subscribe3MonthsId"
            value={inputsState.subscribe3MonthsId ?? ''}
            handler={onChange}
            label="subscribe3MonthsId"
            hasError={errors.subscribe3MonthsId}
          />
          <div className={styles.center_subscribe}>
            <TextInput
              name="subscribe6MonthsId"
              value={inputsState.subscribe6MonthsId ?? ''}
              handler={onChange}
              label="subscribe6MonthsId"
              hasError={errors.subscribe6MonthsId}
            />
          </div>
          <TextInput
            name="subscribe12MonthsId"
            value={inputsState.subscribe12MonthsId ?? ''}
            handler={onChange}
            label="subscribe12MonthsId"
            hasError={errors.subscribe12MonthsId}
          />
          <div className={styles.btn}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClick('subscribe');
              }}
            >
              <HelpOutline />
            </Button>
          </div>
        </div>
        <div className={styles.textarea}>
          <Textarea
            name="playMarketKey"
            label="playMarketKey"
            value={inputsState.playMarketKey}
            handler={onChange}
            hasError={errors.playMarketKey}
          />
          <div className={styles.btn}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClick('playMarketKey');
              }}
            >
              <HelpOutline />
            </Button>
          </div>
        </div>
        <div className={styles.icon_list}>
          <div className={styles.icon_block}>
            <div className={styles.icon_left_content}>
              <div
                className={classNames(styles.icon_block__text, { [styles.has_error]: errors.Icon })}
              >
                <div className={styles.text_header}>
                  <div>
                    <h3 className={styles.desc}>Иконка приложения</h3>
                    <h1 className={styles.title}>{IconNameFile}</h1>
                  </div>
                  <Button style={{ color: 'green' }}>
                    <label htmlFor="iconApp">
                      <GetAppIcon />
                      Загрузить
                      <input
                        className={styles.input_file}
                        type="file"
                        id="iconApp"
                        onChange={onChangeIconApp}
                        required
                        accept="application/zip"
                      />
                    </label>
                  </Button>
                </div>
                <div>
                  {uploadedEarlierLogo ? (
                    <div className={styles.uploaded_earlier}>
                      <p className={styles.desc}>Есть загруженные ранее логотипы приложения</p>
                      <CheckCircleOutline color="primary" />
                    </div>
                  ) : (
                    <p className={styles.desc}>
                      Перед загрузкой ознакомтесь с инструкцией
                      {' "'}
                      Добавление изображений
                      {'" '}
                    </p>
                  )}
                </div>
              </div>
              {errors.Icon ? <div className={styles.has__error}>Заполните это поле</div> : <></>}
            </div>
            <div className={styles.icon_right_content}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onClick('iconApp');
                }}
              >
                <HelpOutline />
              </Button>
            </div>
          </div>
          <div className={styles.icon_block}>
            <div className={styles.icon_left_content}>
              <div
                className={classNames(styles.icon_block__text, { [styles.has_error]: errors.Logo })}
              >
                <div className={styles.text_header}>
                  <div>
                    <h3 className={styles.desc}>Логотип приложения</h3>
                    <h1 className={styles.title}>{LogoNameFile}</h1>
                  </div>
                  <Button style={{ color: 'green' }}>
                    <label htmlFor="iconLogo">
                      <GetAppIcon />
                      Загрузить
                      <input
                        className={styles.input_file}
                        type="file"
                        id="iconLogo"
                        onChange={onChangeIconLogo}
                        required
                        accept="application/zip"
                      />
                    </label>
                  </Button>
                </div>
                <div>
                  {uploadedEarlierIcon ? (
                    <div className={styles.uploaded_earlier}>
                      <p className={styles.desc}>Есть загруженные ранее логотипы приложения</p>
                      <CheckCircleOutline color="primary" />
                    </div>
                  ) : (
                    <p className={styles.desc}>
                      Перед загрузкой ознакомтесь с инструкцией
                      {' "'}
                      Добавление изображений
                      {'" '}
                    </p>
                  )}
                </div>
              </div>
              {errors.Logo ? <div className={styles.has__error}>Заполните это поле</div> : <></>}
            </div>
            <div className={styles.icon_right_content}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onClick('iconLogo');
                }}
              >
                <HelpOutline />
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.application_color_wrap}>
          <div
            className={classNames(styles.application_color_input, {
              [styles.has_error]: errors.hex,
            })}
          >
            <h3 className={styles.desc}>Цвет элементов приложения (RGBA)</h3>
            <ColorPicker value={color} onChange={handleChange} />
          </div>
          <div className={styles.block_button} />
          {errors.hex ? <div className={styles.has__error}>На валидное поле</div> : <></>}
        </div>
        <div className={styles.button_next}>
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            type="submit"
            onClick={handleSubmit}
          >
            Сохранить
          </Button>
        </div>
      </form>
    </div>
  );
});

export { ApplicationSetup };
