/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useMemo } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { TextField } from '@material-ui/core';
import { formatISO, isValid } from 'date-fns';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type IProps = IEntityFieldProps;

export const DateTimeField: FC<IProps> = ({ value, handler, label, error, isEditing, onClick }) => {
  const { t } = useTranslation();

  const onChange = useCallback(
    (valueField) => {
      if (!valueField || !handler || !isValid(valueField)) return;
      if (!moment().isAfter(valueField))
        return (error = t(
          'messages:The date the news was created should not exceed the current date'
        ));
      handler(valueField.toISOString());
    },
    [handler]
  );

  const parsedValue = useMemo(() => {
    const date = parseISO(value);

    return (date && isValid(date) && formatISO(date)) || null;
  }, [value]);

  return isEditing ? (
    <div>
      <DateTimePicker
        renderInput={(props) => (
          <TextField
            variant="outlined"
            {...props}
            label={label}
            helperText={error}
            error={!!error}
          />
        )}
        value={parsedValue}
        onChange={onChange}
        ampm={false}
      />
    </div>
  ) : (
    <div onClick={onClick}>
      {parsedValue ? format(parseISO(parsedValue), 'dd.MM.yyyy HH:mm') : <div>&nbsp;</div>}
    </div>
  );
};
