import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useEntity } from 'icerockdev-admin-toolkit';
import { Link as RouterLink } from 'react-router-dom';
import { FeedEntity } from '../FeedEntity';
import styles from './styles.module.scss';
import { ModalFeedNewsDelete } from '~/application/modules/ModalFeedNewsDelete';
import { ModalNotifications } from '~/application/modules/ModalNotifications';

interface NewsProps {
  id: number;
  isLoading: boolean;
  isButtonShown: boolean;
  data: Record<string, any>;
}

const FeedViewer = observer(({ id, isLoading, isButtonShown, data }: NewsProps) => {
  const { t } = useTranslation();
  const entity = useEntity<FeedEntity>();
  const [page, setPage] = useState(0);

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setPage((currentState) => currentState + 1);
      entity.showMore(id, page + 1, false);
    },
    [data, entity]
  );

  useEffect(() => {
    entity.showMore(id, page, true);
  }, [id]);

  const editData = { title: data.name, id: data.id };

  return (
    <div className={styles.wrap}>
      <div className={styles.header_block}>
        <div>
          <div className={styles.header_block__icon}>
            <LinkIcon />
          </div>
          <div className={styles.header_block__text}>
            <h3 className={styles.header_title}>{t('Link to RSS feeds')}</h3>
            <p className={styles.header_desc}>{data?.url}</p>
          </div>
        </div>
        <div>
          <Button
            color="primary"
            variant="contained"
            to={`${entity.menu.url}/${data.id}/edit`}
            component={RouterLink}
            className={styles.header_button__edit}
          >
            <EditIcon />
          </Button>
          <ModalFeedNewsDelete data={editData} isFeedDelete entity={entity}>
            <Button color="secondary" variant="contained" className={styles.header_button__delete}>
              <DeleteIcon />
            </Button>
          </ModalFeedNewsDelete>
        </div>
      </div>
      <div className={styles.body_block}>
        <h3 className={styles.body_title}>{t('News received via RSS feed')}</h3>
        <div className={styles.rss_feeds}>
          {data.news &&
            data.news?.map((item) => (
              <div key={item.id} className={styles.rss_feed}>
                <div className={styles.feed_left_blok}>
                  <div className={styles.feed_previewImg}>
                    <img src={item.contentPreviewImg} alt="" />
                  </div>
                  <div className={styles.feed_text__block}>
                    <div>
                      <div className={styles.feed_data}>{item.publishedAt}</div>
                      <div className={styles.feed_title}>{item.title}</div>
                    </div>
                    <div className={styles.feed_desc}>{item.contentPreview}</div>
                    <div className={styles.feed_tags}>
                      {item.feed?.map((tags) => (
                        <a href={tags.url}>{tags.name}</a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.feed_buttons}>
                  <ModalNotifications data={item} entity={entity}>
                    <Button className={styles.feed_buttons__item} style={{ color: '#4253AF' }}>
                      <NotificationsActiveIcon />
                    </Button>
                  </ModalNotifications>
                  <ModalFeedNewsDelete data={item} entity={entity}>
                    <Button className={styles.feed_buttons__item} style={{ color: 'red' }}>
                      <DeleteIcon />
                    </Button>
                  </ModalFeedNewsDelete>
                </div>
              </div>
            ))}
        </div>
      </div>
      {isLoading ? (
        <div className={styles.show_more}>
          <CircularProgress />
        </div>
      ) : (
        isButtonShown && (
          <Button className={styles.show_more} onClick={onClick}>
            {t('buttons:Show more')}
          </Button>
        )
      )}
    </div>
  );
});

export { FeedViewer };
