import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import styles from './styles.module.scss';
import { ModalNotifications } from '~/application/modules/ModalNotifications';
import { NewsEntity } from '~/pages/news/components/NewsEntity';

const SubscriptionAddButton: FC<IEntityFieldProps> = ({ data }) => {
  const entity = useEntity<NewsEntity>();
  return (
    <div className={styles.wrap}>
      <ModalNotifications data={data} entity={entity}>
        <Button style={{ color: '#3f51b5' }}>
          <NotificationsActiveIcon />
        </Button>
      </ModalNotifications>
    </div>
  );
};

export { SubscriptionAddButton };
