import React, { Fragment, ReactElement } from 'react';
import {
  Entity,
  ENTITY_ERRORS,
  IEntityCreateFunction,
  IEntityCreateFunctionResult,
  IEntityField,
  IEntityProps,
} from 'icerockdev-admin-toolkit';
import { action, computed, flow, observable, reaction, toJS } from 'mobx';
import { observer, Provider } from 'mobx-react';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { CancellablePromise } from 'mobx/lib/api/flow';
import {
  blockCustomerItem,
  blockSubscriptionItem,
  unBlockCustomerItem,
  unBlockSubscriptionItem,
  getUser,
} from '~/pages/user/api';
import { EntityList } from '~/application/modules/EntityList';
import { EntityListEmptyDataPlaceholder } from '~/application/modules/EntityListEmptyDataPlaceholder';
import { Subscription } from '~/pages/user/constants';
import { TabsView } from '~/pages/user/components/TabsView';
import { SubscriptionHistory } from '~/pages/user/components/SubscriptionHistory';
import { UserBreadcrumbs } from '~/pages/user/components/UserBreadcrumbs';
import { SUBSCRIPTION_FIELDS } from '~/pages/user/fields';
import { SubscriptionEntityViewer } from '~/pages/user/components/SubscriptionEntityViewer';
import { UserHeadButtons } from '../UserHeadButtons';

export interface UserEntityProps extends IEntityProps {
  createSubscriptionFn?: IEntityCreateFunction;
}

class UserEntity extends Entity {
  @observable createSubscriptionFn?: IEntityCreateFunction = undefined;

  @observable subscriptionEditorData: Record<string, any> = {};

  @observable userEditorData: Record<string, any> = this.editorData;

  @observable subscriptionEditorFieldErrors: Record<string, string> = {};

  @observable subscriptionHistory: Subscription[] = [];

  @observable isSubscriptionHistoryLoading = true;

  @observable fullName = '';

  constructor(fields?: Partial<UserEntityProps>) {
    super(fields as any);
    reaction(
      () => this.getItemsInstance,
      () => this.afterGetItems()
    );
  }

  @action
  afterGetItems = async () => {
    this.getItemsInstance?.then(() => {
      this.fullName = [
        this.editorData?.firstName,
        this.editorData?.middleName,
        this.editorData?.lastName,
      ].join(' ');
      this.subscriptionHistory = this.editorData.subscriptionHistory;
      this.isSubscriptionHistoryLoading = false;
    });
  };

  @action
  setSubscriptionEditorData = (data: Record<string, any>) => {
    this.subscriptionEditorData = data;
  };

  @action
  resetSubscriptionFieldError = (field: string) => {
    delete this.subscriptionEditorFieldErrors[field];
  };

  createSubscriptionItemInstance?: CancellablePromise<any>;

  @action
  validateSubscriptionSubmitFields = (
    fields: IEntityField[],
    data: Record<string, any>,
    isCreating = false
  ): boolean => {
    this.subscriptionEditorFieldErrors = fields
      .filter((field) => (isCreating && !field.hideInCreate) || (!isCreating && !field.hideInEdit))
      .reduce((obj, field) => {
        if (this.isValidField(field, data[field.name])) {
          return obj;
        }

        return {
          ...obj,
          [field.name]:
            (field.validator && field.validator(data[field.name], this)) ||
            i18n.t(ENTITY_ERRORS.FIELD_IS_REQUIRED),
        };
      }, {});

    return Object.keys(this.subscriptionEditorFieldErrors).length === 0;
  };

  @action
  createSubscriptionItem = (id: number, appId: number) => {
    this.createSubscriptionItemInstance = flow(function* (this: UserEntity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.subscriptionEditorData);
        data.adminId = id;
        data.appId = appId;

        if (!this.validateSubscriptionSubmitFields(SUBSCRIPTION_FIELDS, data, true)) {
          throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
        }

        if (!this.api?.subscriptionCreate?.url || !this.createSubscriptionFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const result: IEntityCreateFunctionResult = yield this.parent?.auth?.withToken(
          this.createSubscriptionFn,
          {
            url: this.api?.subscriptionCreate?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.fetchItems();
        // noinspection TypeScriptValidateTypes
        this.parent?.history.push(`${this.menu.url}/${id}`);
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  blockItem = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    await this.parent.auth.withToken(blockCustomerItem, { url: `${this?.api?.update.url}/${id}` });
    const result = await this.parent.auth.withToken(getUser, {
      url: `${this?.api?.update.url}`,
      id,
    });
    await this.fetchItems();

    this.editorData = result.data;

    this.isLoading = true;
  };

  @action
  unBlockItem = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    await this.parent.auth.withToken(unBlockCustomerItem, {
      url: `${this?.api?.update.url}/${id}`,
    });
    await this.parent.auth.withToken(getUser, { url: `${this?.api?.update.url}`, id });
    const result = await this.parent.auth.withToken(getUser, {
      url: `${this?.api?.update.url}`,
      id,
    });
    await this.fetchItems();

    this.editorData = result.data;
    this.isLoading = true;
  };

  @action
  blockSubscription = async (userId: number, subscriptionId: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isSubscriptionHistoryLoading = true;

    try {
      await this.parent.auth.withToken(blockSubscriptionItem, {
        url: `${this?.api?.subscriptionBlock.url}/${subscriptionId}`,
      });
      await this.getItem(userId);
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    } finally {
      this.isSubscriptionHistoryLoading = false;
    }
  };

  @action
  unBlockSubscription = async (userId: number, subscriptionId: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isSubscriptionHistoryLoading = true;

    try {
      await this.parent.auth.withToken(unBlockSubscriptionItem, {
        url: `${this?.api?.subscriptionUnBlock.url}/${subscriptionId}`,
      });
      await this.getItem(userId);
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    } finally {
      this.isSubscriptionHistoryLoading = false;
    }
  };

  @computed
  get ListBody() {
    return observer(() => (
      <EntityList
        fields={this.fields}
        data={this.data}
        extra={this.ListExtra}
        isLoading={this.isLoading}
        url={this.menu.url}
        selected={this.selected}
        sortBy={this.sortBy}
        sortDir={this.sortDir}
        canView={this.viewable && this.canView}
        canEdit={this.editable && this.canEdit}
        canSelect={this.selectable}
        setSelected={this.setSelected}
        onSortChange={this.setSort}
        withToken={this.parent?.auth?.withToken}
        entity={this}
        emptyDataPlaceholder={
          <EntityListEmptyDataPlaceholder label={i18n.t('messages:No users')} />
        }
      />
    ));
  }

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        isSubscriptionCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        isSubscriptionCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <UserBreadcrumbs
          data={{ ...this.editorData, name: this.fullName }}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          isSubscriptionCreating={isSubscriptionCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={false}
        />
      )
    );
  }

  @computed
  get Viewer() {
    return observer(({ id }: { id: string }) => (
      <>
        <this.ViewerHead id={id} />
        <TabsView
          firstChildren={<this.ViewerBody id={id} />}
          secondChildren={<this.ViewerSubscriptionHistory userId={Number(id)} />}
        />
        <this.ViewerFooter id={id} />
      </>
    ));
  }

  @computed
  get ViewerSubscriptionHistory() {
    return observer(({ userId }: { userId: number }) => (
      <SubscriptionHistory
        isLoading={this.isSubscriptionHistoryLoading}
        userId={userId}
        url={this.menu.url}
        data={this.subscriptionHistory}
      />
    ));
  }

  @computed
  get SubscriptionCreatorHead() {
    return observer(({ id }: { id: string }) => (
      <this.Breadcrumbs id={id} buttons={<></>} isSubscriptionCreating />
    ));
  }

  @computed
  get SubscriptionCreatorBody() {
    return observer(({ id }: { id: string }) => {
      if (!this.canCreate) return this.forbiddenPlaceholder;

      return (
        <SubscriptionEntityViewer
          id={Number(id)}
          appId={this.editorData?.appId}
          fields={SUBSCRIPTION_FIELDS}
          errors={this.subscriptionEditorFieldErrors}
          url={this.menu.url}
          onSave={this.createSubscriptionItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetSubscriptionFieldError}
          isCreating
          isEditing
          isLoading={this.isLoading}
          setEditorData={this.setSubscriptionEditorData}
          data={this.subscriptionEditorData}
          getItem={this.getItem}
          cancelGetItem={this.getItemsCancel}
          viewable={false}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get SubscriptionCreator() {
    return observer(({ id }: { id: string }) => (
      <>
        <this.SubscriptionCreatorHead id={id} />
        <this.SubscriptionCreatorBody id={id} />
      </>
    ));
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => <UserHeadButtons data={this.editorData} />);
  }

  @computed
  get output() {
    return observer(() => (
      <Provider entity={this}>
        <Switch>
          <Route
            path={`${this.menu.url}/:id/subscription/create`}
            component={({
              match: {
                params: { id },
              },
            }: RouteComponentProps<{ id: string }>) => <this.SubscriptionCreator id={id} />}
          />
          <Route path={`${this.menu.url}/create`} component={this.Creator} />
          <Route
            path={`${this.menu.url}/:id/edit`}
            component={({
              match: {
                params: { id },
              },
            }: RouteComponentProps<{ id: string }>) => <this.Editor id={id} />}
          />
          <Route
            path={`${this.menu.url}/:id/`}
            component={({
              match: {
                params: { id },
              },
            }: RouteComponentProps<{ id: string }>) => <this.Viewer id={id} />}
          />
          <Route path={this.menu.url} component={this.List} />
        </Switch>
      </Provider>
    ));
  }
}

export { UserEntity };
