import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './styles.module.scss';
import { NewsEntity } from '~/pages/news/components/NewsEntity';
import { ModalFeedNewsDelete } from '~/application/modules/ModalFeedNewsDelete';

const NewsDeleteButton: FC<IEntityFieldProps> = ({ data }) => {
  const entity = useEntity<NewsEntity>();

  return (
    <div className={styles.wrap}>
      <ModalFeedNewsDelete data={data} entity={entity}>
        <Button color="secondary">
          <DeleteIcon />
        </Button>
      </ModalFeedNewsDelete>
    </div>
  );
};

export { NewsDeleteButton };
