import React from 'react';
import { Grid, Button, WithStyles, withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useEntity } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import styles from './styles';
import { ModalFeedNewsDelete } from '~/application/modules/ModalFeedNewsDelete';
import { NewsEntity } from '~/pages/news/components/NewsEntity';
import { ModalNotifications } from '~/application/modules/ModalNotifications';

type BreadcrumbsButtonProps = WithStyles<typeof styles> & {
  id?: string;
  url: string;
  data: Record<string, any>;
};

const NewsBreadcrumbsButton = withStyles(styles)(
  observer(({ url, classes, id, data }: BreadcrumbsButtonProps) => {
    const entity = useEntity<NewsEntity>();

    return (
      <div className={classes.breadcrumbs}>
        <Grid container alignItems="center">
          <ModalNotifications data={data} entity={entity}>
            <Button color="primary" variant="contained" className={classes.buttons}>
              <NotificationsActiveIcon />
            </Button>
          </ModalNotifications>
          <Button
            color="primary"
            variant="contained"
            to={`${url}/${id}/edit`}
            component={RouterLink}
            className={classes.buttons}
          >
            <EditIcon />
          </Button>
          <ModalFeedNewsDelete data={data} isNewsDelete entity={entity}>
            <Button color="secondary" variant="contained" className={classes.buttons}>
              <DeleteIcon />
            </Button>
          </ModalFeedNewsDelete>
        </Grid>
      </div>
    );
  })
);

export { NewsBreadcrumbsButton };
