/* eslint-disable */
import {
  IAuthLogoutFn,
  IAuthSignupFn,
  IJWTAuthRequestFn,
  IJWTTokenRefreshFn,
  UNAUTHORIZED,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import { authorizedHandler, unauthorizedHandler } from '~/utils/interceptor';
import { API_URLS } from '~/utils/constants';
import { UserRole, UserStatus } from '~/pages/user/constants';
import NotificationError from '~/utils/notificationError';
import {
  AuthPasswordChangeFn,
  AuthPasswordConfirmFn,
  AuthPasswordRestoreFn,
  AuthSignUpConfirmFn,
} from '~/auth/index';

export const getUserProfileFn = async (token: string) => {
  try {
    const {
      data: { data: user },
    } = await axios
      .get(API_URLS.user.profile, { headers: { Authorization: `Bearer ${token}` } })
      .catch(authorizedHandler);

    if (!user) {
      throw new Error(UNAUTHORIZED);
    }

    return user;
  } catch (e) {
    return {
      id: 0,
      appId: 0,
      status: UserStatus.Active,
      role: UserRole.SuperAdmin,
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      email: '',
      website: '',
      subscription: null,
      subscriptionType: null,
    };
  }
};

export const authRequestFn: IJWTAuthRequestFn = async (email: string, password: string) => {
  const {
    data: {
      data: { accessToken, refreshToken },
    },
  } = await axios
    .post(API_URLS.auth.signin, {
      email,
      password,
    })
    .catch(unauthorizedHandler);

  if (!accessToken || !refreshToken) {
    throw new NotificationError('Ошибка', 'Неверный логин или пароль');
  }

  const user = await getUserProfileFn(accessToken);

  return {
    user,
    tokens: {
      access: accessToken,
      refresh: refreshToken,
    },
    error: '',
  };
};

export const tokenRefreshFn: IJWTTokenRefreshFn = async (refreshToken) => {
  try {
    const result = await axios
      .post(API_URLS.auth.refresh, { refreshToken })
      .catch(authorizedHandler);

    if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }

    return {
      access: result.data.data.accessToken,
      refresh: result.data.data.refreshToken,
    };
  } catch (e) {
    return {
      access: '',
      refresh: '',
    };
  }
};

export const authLogoutFn: IAuthLogoutFn = async () => {
  const { refresh } = JSON.parse(localStorage.getItem('tokens') ?? '');

  try {
    const result = await axios
      .post(API_URLS.auth.logout, {
        refreshToken: refresh,
      })
      .catch(authorizedHandler);

    if (!result.data.success) {
      throw new Error(UNAUTHORIZED);
    }

    return result;
  } catch (e) {
    return {
      error: e.message,
    };
  }
};

// Restore password
// 1st step
export const authPasswordRestoreFn: AuthPasswordRestoreFn = async (email) =>
  await axios.post(API_URLS.auth.restore, { email }).catch(unauthorizedHandler);

// 2nd step
export const authPasswordConfirmFn: AuthPasswordConfirmFn = async (confirmationToken) =>
  await axios.post(`${API_URLS.auth.restore}/${confirmationToken}`, {}).catch(unauthorizedHandler);

// 3rd step
export const authPasswordChangeFn: AuthPasswordChangeFn = async (confirmationToken, password) =>
  await axios
    .put(API_URLS.auth.restore, {
      confirmationToken,
      password,
      passwordRepeat: password,
    })
    .catch(unauthorizedHandler);

// Sign Up
// 1st step
export const authSignUpConfirmFn: AuthSignUpConfirmFn = async (confirmationToken) =>
  await axios.post(`${API_URLS.auth.signup}/${confirmationToken}`, {}).catch(unauthorizedHandler);

// 2nd step
export const authSignUpFn: IAuthSignupFn = async (data: any) =>
  await axios
    .put(API_URLS.auth.signup, {
      confirmationToken: data.confirmationToken,
      password: data.password,
      passwordRepeat: data.password,
    })
    .catch(unauthorizedHandler);

export const authSignInFn: IAuthSignupFn = async (data) =>
  await axios.post(API_URLS.auth.signin, { data }).catch(unauthorizedHandler);
