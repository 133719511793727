import React, { useCallback } from 'react';
import { useSlateStatic } from 'slate-react';
import { IconButton } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { useEntity } from 'icerockdev-admin-toolkit';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { insertImage } from '~/pages/news/slate/editor';
import { NewsEntity } from '../../../NewsEntity';

export const ImageButton = () => {
  const entity = useEntity<NewsEntity>();
  const editor = useSlateStatic();
  const { t } = useTranslation();
  const handleChange = useCallback(async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    if (file.size > 10485760) {
      alert(t('messages:The image must not exceed 10Mb'));
    } else {
      const result = await entity.addWisywigFile(formData);

      insertImage(editor, String(result.data.data));
    }
  }, []);

  return (
    <IconButton>
      <label className={styles.label_height} htmlFor="WisywigImage">
        <ImageIcon />
        <input
          style={{ display: 'none' }}
          type="file"
          id="WisywigImage"
          onChange={(e) => handleChange(e)}
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
          }}
        />
      </label>
    </IconButton>
  );
};
