import { IEntityField } from 'icerockdev-admin-toolkit';
import { PhoneField } from '~/application/modules/PhoneField';

export const ACCOUNT_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    required: true,
    hideInEdit: true,
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Телефон',
    required: true,
    hideInEdit: true,
    component: PhoneField,
  },
  {
    type: 'string',
    name: 'website',
    label: 'Адрес сайта',
    required: true,
    hideInEdit: true,
  },
];
