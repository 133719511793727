import React, { FC } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { SUBSCRIPTION_STATUS_NAMES, Subscription } from '~/pages/user/constants';

interface SubscriptionHistoryProps {
  data: Subscription[];
}

const SubscriptionHistory: FC<SubscriptionHistoryProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{t('Subscription history')}</div>
      <div className={styles.history_list}>
        <div className={styles.history_item}>
          <div>
            <h3 className={styles.history_title}>{t('Subscription start date')}</h3>
          </div>
          <div>
            <h3 className={styles.history_title}>{t('Subscription end date')}</h3>
          </div>
          <div>
            <h3 className={styles.history_title}>{t('Subscription status')}</h3>
          </div>
        </div>
        {data &&
          data.map((item) => (
            <div key={item.id} className={styles.history_item}>
              <div>
                <h3 className={styles.history_title__list}>
                  {moment(item.startAt).format('DD.MM.YYYY')}
                </h3>
              </div>
              <div>
                <h3 className={styles.history_title__list}>
                  {moment(item.expiredAt).format('DD.MM.YYYY')}
                </h3>
              </div>
              <div>
                <h3 className={styles.history_title__list}>
                  {SUBSCRIPTION_STATUS_NAMES[item.status]}
                </h3>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export { SubscriptionHistory };
