import { Entity, ENTITY_ERRORS, IEntityGetFunctionResult } from 'icerockdev-admin-toolkit';
import { action, computed, flow, reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { Account } from '~/pages/account/components/Account';
import { User } from '~/pages/user/constants';

export class AccountEntity extends Entity {
  @action
  onMount = () => {
    reaction(() => this.page, this.getItem);
    this.getItem();
  };

  @action
  onUnmount = () => {
    this.getItemsCancel();
  };

  @action
  getItem = () => {
    this.getItemsInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';

      if (Object.keys(this.editorFieldErrors).length) {
        this.editorFieldErrors = {};
      }

      try {
        if (!this.api?.get?.url || !this.getItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const result: IEntityGetFunctionResult = yield this.parent?.auth?.withToken(
          this.getItemsFn,
          {
            url: this.api?.get?.url,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.editorData = result.data;
        this.isLoading = false;
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        // noinspection TypeScriptValidateTypes
        this.parent?.history.push(this.menu.url);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @computed
  get output() {
    return observer(() => <this.AccountView />);
  }

  @computed
  get AccountView() {
    return observer(() => <Account entity={this} data={this.editorData as User} />);
  }
}
