export const API_URLS = {
  auth: {
    signin: '/admin/v1/auth/signin',
    signup: '/admin/v1/auth/signup',
    refresh: '/admin/v1/auth/refresh',
    logout: '/admin/v1/auth/logout',
    restore: '/admin/v1/auth/restore-password',
  },
  user: {
    profile: '/admin/v1/user/profile',
    user: '/admin/v1/user',
  },
  news: {
    news: '/admin/v1/news',
  },
  subscription: {
    check: '/admin/v1/subscription/expire',
  },
  customization: {
    get: '/admin/v1/apps',
    file: '/admin/v1/file/key',
    getSetup: '/admin/v1/appGeneration',
    getVersion: '/admin/v1/appGeneration/info',
  },
};
