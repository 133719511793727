import React, { FC } from 'react';
import { useSlate } from 'slate-react';
import { IconButton } from '@material-ui/core';
import { isLeafActive, toggleLeaf } from '~/pages/news/slate/editor';

export type LeafButtonProps = {
  name: string;
  children?: React.ReactNode;
};

const LeafButton: FC<LeafButtonProps> = ({ name, children }) => {
  const editor = useSlate();
  return (
    <IconButton
      color={isLeafActive(editor, name) ? 'inherit' : 'default'}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleLeaf(editor, name);
      }}
    >
      {children}
    </IconButton>
  );
};

export { LeafButton };
