import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import { useConfig } from 'icerockdev-admin-toolkit';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const SignIn: FC = () => {
  const config = useConfig();
  const { t } = useTranslation();
  const { auth } = config;

  const onForgotPassword = () => {
    config.history.push('/restore');
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmitCapture = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      auth!.sendAuthRequest(email, password);
    },
    [email, password, auth]
  );

  const onEmailChange = useCallback((event) => setEmail(event.target.value), [setEmail]);

  const onPasswordChange = useCallback((event) => setPassword(event.target.value), [setPassword]);

  return (
    <div className={styles.wrap}>
      <Helmet>
        <title>{t('Sign In')}</title>
      </Helmet>

      <h3 className={styles.header}>{t('Sign In')}</h3>

      <form noValidate onSubmit={onSubmitCapture}>
        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          defaultValue={email}
          onChange={onEmailChange}
          autoFocus
        />

        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('Password')}
          type="password"
          id="password"
          defaultValue={password}
          onChange={onPasswordChange}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" onClick={onForgotPassword} className={styles.forgot}>
                {t('Forgot password?')}
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!email.length || !password.length}
          className={styles.button}
        >
          {t('Log In')}
        </Button>
      </form>
    </div>
  );
};

export { SignIn };
