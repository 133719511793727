import React, { useEffect, useState } from 'react';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './styles.module.scss';

export const EmojiField = ({ value, handler }) => {
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickDelete = () => {
    setChosenEmoji(null);
    handler(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject.emoji);
    handler(emojiObject.emoji);
    setOpen(false);
  };

  useEffect(() => {
    setChosenEmoji(value);
  });

  return (
    <div>
      {chosenEmoji ? (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <EmojiData
          chosenEmoji={chosenEmoji}
          handleClickOpen={handleClickOpen}
          handleClickDelete={handleClickDelete}
        />
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Добавить Смайлик
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div>
            <Picker
              onEmojiClick={onEmojiClick}
              disableAutoFocus
              skinTone={SKIN_TONE_MEDIUM_DARK}
              groupNames={{ smileys_people: 'PEOPLE' }}
              native
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const EmojiData = ({ chosenEmoji, handleClickOpen, handleClickDelete }) => (
  <div className={styles.emoji_wrap}>
    <div className={styles.emoji}>{chosenEmoji}</div>
    <div className={styles.emoji}>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Изменить Смайлик
      </Button>
    </div>
    <Button variant="outlined" color="primary" onClick={handleClickDelete}>
      Удалить Смайлик
    </Button>
  </div>
);
