import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  breadcrumbs: {
    alignItems: 'center',
    display: 'flex',
    padding: '40px 24px 24px',
  },
  buttons: {
    width: 48,
    minWidth: 48,
    height: 48,
    marginRight: 16,
  },
});
