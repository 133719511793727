import { IEntityField } from 'icerockdev-admin-toolkit';
import { NewsWysiwygField } from '~/pages/news/components/NewsWysiwygField';
import { SubscriptionAddButton } from './components/SubscriptionAddButton';
import { NewsDeleteButton } from './components/NewsDeleteButton';
import { NewsImageField } from './components/NewsImageField';
import { DateTimeField } from './components/DateTimeField';
import { minMaxLength } from '~/utils/validators';
import {
  MIN_STRING_LENGTH,
  MAX_CONTENT_PREVIEW_LENGTH,
  MAX_TITLE_LENGTH,
  MAX_AVTOR_LENGTH,
} from './constant';

export const NEWS_FIELDS: IEntityField[] = [
  {
    type: 'custom',
    name: 'contentPreviewImg',
    label: 'Изображение новости',
    hideInList: true,
    component: NewsImageField,
  },
  {
    type: 'string',
    name: 'title',
    label: 'Заголовок новости',
    required: true,
    validator: minMaxLength(
      MIN_STRING_LENGTH,
      MAX_TITLE_LENGTH
    )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_TITLE_LENGTH} символов`),
  },
  {
    type: 'custom',
    name: 'publishedAt',
    label: 'Дата/время публикации',
    required: true,
    component: DateTimeField,
    hideInEdit: true,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'author',
    label: 'Автор',
    required: true,
    validator: minMaxLength(
      MIN_STRING_LENGTH,
      MAX_AVTOR_LENGTH
    )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_AVTOR_LENGTH} символов`),
  },
  {
    type: 'textarea',
    name: 'contentPreview',
    label: 'Краткое описание',
    required: true,
    hideInList: true,
    hideInView: true,
    validator: minMaxLength(
      MIN_STRING_LENGTH,
      MAX_CONTENT_PREVIEW_LENGTH
    )(`Должно быть от ${MIN_STRING_LENGTH} до ${MAX_CONTENT_PREVIEW_LENGTH} символов`),
  },
  {
    type: 'richtext',
    component: NewsWysiwygField,
    name: 'content',
    label: '',
    required: true,
    hideInList: true,
    hideInView: true,
  },

  // Buttons
  {
    type: 'custom',
    name: 'subscriptionAdd',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    component: SubscriptionAddButton,
    hideInList: false,
    hideInView: true,
  },
  {
    type: 'custom',
    name: ' ',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    component: NewsDeleteButton,
    hideInList: false,
    hideInView: true,
  },
];
