import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import moment from 'moment';
import { NotificationEntity } from '~/pages/notification/components/NotificationEntity';

interface IProps extends IEntityFieldProps {
  entity: NotificationEntity;
}

const CreatedAtField: FC<IProps> = ({ data }) => {
  const CreatedAt = data?.createdAt ? moment(data.createdAt).format('DD.MM.YYYY hh:mm') : '-';

  return <div>{CreatedAt}</div>;
};

export { CreatedAtField };
