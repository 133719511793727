import React, { useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEntity } from 'icerockdev-admin-toolkit';
import { Button, CircularProgress } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { UserEntity } from '~/pages/user/components/UserEntity';
import { SubscriptionModal } from '../SubscriptionModal';
import {
  Subscription,
  SUBSCRIPTION_STATUS_NAMES,
  SubscriptionStatus,
} from '~/pages/user/constants';
import styles from './styles.module.scss';

interface SubscriptionHistoryProps {
  isLoading: boolean;
  userId: number;
  url: string;
  data: Subscription[];
}

const SubscriptionHistory = observer(
  ({ isLoading, userId, url, data }: SubscriptionHistoryProps) => {
    const { t } = useTranslation();
    const entity = useEntity<UserEntity>();

    const onClick = useCallback(
      (event, subscriptionId) => {
        event.preventDefault();
        event.stopPropagation();
        entity.unBlockSubscription(userId, subscriptionId);
      },
      [data, entity, userId]
    );

    if (isLoading) {
      return (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className={styles.wrap}>
        <div className={styles.title}>{t('Subscription history')}</div>
        <div className={styles.history_list}>
          <div className={styles.history_item}>
            <div>
              <h3 className={styles.history_title}>{t('Subscription start date')}</h3>
            </div>
            <div>
              <h3 className={styles.history_title}>{t('Subscription end date')}</h3>
            </div>
            <div>
              <h3 className={styles.history_title}>{t('Subscription status')}</h3>
            </div>
            <div>
              <span className={styles.history_title}>
                <Button
                  color="primary"
                  variant="text"
                  to={`${url}/${userId}/subscription/create`}
                  component={RouterLink}
                >
                  {t('buttons:Create new subscription')}
                </Button>
              </span>
            </div>
          </div>
          {data?.map((item) => (
            <div key={item.id} className={styles.history_item}>
              <div>
                <h3 className={styles.history_title__list}>
                  {moment(item.startAt).format('DD.MM.YYYY')}
                </h3>
              </div>
              <div>
                <h3 className={styles.history_title__list}>
                  {moment(item.expiredAt).format('DD.MM.YYYY')}
                </h3>
              </div>
              <div>
                <h3 className={styles.history_title__list}>
                  {SUBSCRIPTION_STATUS_NAMES[item.status]}
                </h3>
              </div>
              <div className={styles.history_title__list_button}>
                {item.status === SubscriptionStatus.Active ? (
                  <SubscriptionModal userId={userId} item={item}>
                    <Button color="secondary">
                      <LockIcon />
                    </Button>
                  </SubscriptionModal>
                ) : (
                  <Button
                    style={{ color: 'green' }}
                    onClick={(e) => {
                      onClick(e, item.id);
                    }}
                  >
                    <LockOpenIcon />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export { SubscriptionHistory };
