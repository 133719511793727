import { AccountEntity } from '~/pages/account/components/AccountEntity';
import { ACCOUNT_FIELDS } from './fields';
import { UserRole } from '~/pages/user/constants';
import { getProfile, updatePasswordUser } from './api';

const Account = new AccountEntity({
  title: 'Мои данные',
  fields: ACCOUNT_FIELDS,
  editable: true,
  menu: {
    enabled: true,
    url: '/profile',
    label: 'Мои данные',
  },
  api: {
    get: {
      url: '/admin/v1/user/profile',
      method: 'get',
    },
    update: {
      url: '/admin/v1/user',
      method: 'post',
    },
  },
  roles: [UserRole.AppAdmin],
  permissions: {
    list: [UserRole.AppAdmin],
  },
  getItemsFn: getProfile,
  updateItemsFn: updatePasswordUser,
});

export default Account;
