import { isEmail as isEmailValidator } from 'validator';

export const minMax = (min: number, max: number) => (message: string) => (val: number) => {
  if (!val) return '';
  return val < min || val > max ? message : '';
};

export const minLength = (min: number) => (message: string) => (val: number) => {
  if (!val) return '';
  return val < min ? message : '';
};

export const minMaxLength = (min: number, max: number) => (message: string) => (val: string) =>
  !val || val.length < min || val.length > max ? message : '';

export const minMaxLengthOrEmpty =
  (min: number, max: number) => (message: string) => (val: string) => {
    if (!val) return '';
    return val.length < min || val.length > max ? message : '';
  };

export const regexp = (exp: RegExp) => (message: string) => (val: string) => {
  if (!val) return '';
  return exp.test(val) ? '' : message;
};

export const isEmail = (message: string) => (val: string) => {
  if (!val) return message;
  return isEmailValidator(val) ? '' : message;
};
