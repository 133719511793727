import { CustomTypes, Editor, Element as SlateElement, Text, Transforms } from 'slate';
import { jsx } from 'slate-hyperscript';
import escapeHtml from 'escape-html';
import { EditorElements, ImageElement, LeafText } from '~/pages/news/slate/types';

export const isElementActive = (editor: CustomTypes['Editor'], type: EditorElements) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
  });

  return !!match;
};

export const toggleElement = (editor: CustomTypes['Editor'], type: EditorElements) => {
  const isActive = isElementActive(editor, type);

  const props: Partial<SlateElement> = {
    type: isActive ? EditorElements.Paragraph : type,
  };

  Transforms.setNodes(editor, props);
};

export const isLeafActive = (editor: CustomTypes['Editor'], name: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[name] === true : false;
};

export const toggleLeaf = (editor: CustomTypes['Editor'], name: string) => {
  const isActive = isLeafActive(editor, name);

  if (isActive) {
    Editor.removeMark(editor, name);
  } else {
    Editor.addMark(editor, name, true);
  }
};

export const serialize = (node) => {
  if (Array.isArray(node)) {
    return node.map((n) => serialize(n)).join('');
  }
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    const textNode = node as LeafText;
    if (textNode?.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (textNode?.italic) {
      string = `<em>${string}</em>`;
    }
    if (textNode?.underline) {
      string = `<u>${string}</u>`;
    }
    if (textNode?.strikethrough) {
      string = `<s>${string}</s>`;
    }
    if (textNode?.url) {
      string = `<a href=${textNode?.url}>${string}</a>`;
    }
    return string;
  }

  const children = node?.children?.map((n) => serialize(n)).join('');
  switch (node.type) {
    case EditorElements.Title:
      return `<h2>${children}</h2>`;
    case EditorElements.Paragraph:
      return `<p>${children}</p>`;
    case EditorElements.Blockquote:
      return `<blockquote>${children}</blockquote>`;
    case EditorElements.Image:
      return `<figure><img src=${node.url} alt="" /><figcaption>${children}</figcaption></figure>`;
    default:
      return children;
  }
};

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }

  let children = Array.from(el.childNodes).map(deserialize);

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  switch (el.nodeName) {
    case 'H2':
      return jsx('element', { type: EditorElements.Title }, children);
    case 'BLOCKQUOTE':
      return jsx('element', { type: EditorElements.Blockquote }, children);
    case 'FIGURE':
      return jsx(
        'element',
        { type: EditorElements.Image, url: el.childNodes[0].src },
        el.childNodes[1].innerText || { text: '' }
      );
    case 'STRONG':
      return jsx('text', { bold: true }, children);
    case 'U':
      return jsx('text', { underline: true }, children);
    case 'EM':
      return jsx('text', { italic: true }, children);
    case 'S':
      return jsx('text', { strikethrough: true }, children);
    case 'A':
      return jsx('text', { url: el.childNodes[0]?.parentNode.href || '' }, children);
    default:
      return jsx('element', { type: EditorElements.Paragraph }, children);
  }
};

export const insertImage = (editor, url: string) => {
  const text = { text: '' };
  const image: ImageElement = { type: EditorElements.Image, url, children: [text] };
  Transforms.insertNodes(editor, [image]);
};

export const insertLink = (editor, data) => {
  const text = { url: data.link, text: data.name };
  Transforms.insertNodes(editor, [text]);
};
