import React, { ReactElement } from 'react';
import { Entity, EntityBreadcrumbs } from 'icerockdev-admin-toolkit';
import { computed, action } from 'mobx';
import { observer } from 'mobx-react';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { EntityList } from '~/application/modules/EntityList';
import { EntityListEmptyDataPlaceholder } from '~/application/modules/EntityListEmptyDataPlaceholder';
import { NewsViewer } from '~/pages/news/components/NewsViewer';
import { addFileNewsApi, deleteNewsApi, fetchNews, addNotificationApi } from '../../api';
import { DeletableNewsEntity } from '~/application/modules/ModalFeedNewsDelete';
import { NewsBreadcrumbsButton } from '~/pages/news/components/NewsBreadcrumbsButton';
import { NewsEntityEditor } from '~/pages/news/components/NewsEntityEditor';

export class NewsEntity extends Entity implements DeletableNewsEntity {
  @action
  deleteNews = async (id: number, feedId: number, isFeedDelete: boolean | undefined) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    await this.parent.auth.withToken(deleteNewsApi, {
      url: this?.api?.delete.url,
      data: id,
    });

    const result = await this.parent.auth.withToken(fetchNews, {
      url: this?.api?.get.url,
    });

    this.data = result.data.list;
    this.isLoading = false;
  };

  @action
  addNewsFile = (file) => {
    if (!this.parent?.auth?.withToken) return;

    const result = this.parent.auth.withToken(addFileNewsApi, {
      url: this?.api?.addFile.url,
      data: file,
    });

    // eslint-disable-next-line consistent-return
    return result;
  };

  @action
  addWisywigFile = (file) => {
    if (!this.parent?.auth?.withToken) return;

    const result = this.parent.auth.withToken(addFileNewsApi, {
      url: this?.api?.addFile.url,
      data: file,
    });

    // eslint-disable-next-line consistent-return
    return result;
  };

  @action
  addNotification = (data) => {
    if (!this.parent?.auth?.withToken) return;
    this.parent.auth.withToken(addNotificationApi, {
      url: this?.api?.addNotification.url,
      data: { ...data, userId: this.parent?.auth?.user.id },
    });
  };

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <EntityBreadcrumbs
          data={id}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={false}
        />
      )
    );
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => (
      <NewsBreadcrumbsButton data={this.editorData} id={id} url={this.menu.url} />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => (
      <EntityList
        fields={this.fields}
        data={this.data}
        extra={this.ListExtra}
        isLoading={this.isLoading}
        url={this.menu.url}
        selected={this.selected}
        sortBy={this.sortBy}
        sortDir={this.sortDir}
        canView={this.viewable && this.canView}
        canEdit={this.editable && this.canEdit}
        canSelect={this.selectable}
        setSelected={this.setSelected}
        onSortChange={this.setSort}
        withToken={this.parent?.auth?.withToken}
        entity={this}
        emptyDataPlaceholder={<EntityListEmptyDataPlaceholder label={i18n.t('messages:No news')} />}
      />
    ));
  }

  @computed
  get Viewer() {
    return observer(({ id }: { id: string }) => (
      <>
        <this.ViewerHead id={id} />
        <this.NewsViewerBody id={Number(id)} data={this.editorData} />
        <this.ViewerFooter id={id} />
      </>
    ));
  }

  @computed
  get NewsViewerBody() {
    return observer(({ id, data }: { id: number; data: Record<string, any> }) => (
      <NewsViewer id={id} data={data} getItem={this.getItem} cancelGetItem={this.getItemsCancel} />
    ));
  }

  @computed
  get CreatorBody() {
    return observer(() => {
      if (!this.canCreate) return this.forbiddenPlaceholder;

      return (
        <NewsEntityEditor
          fields={this.fields}
          errors={this.editorFieldErrors}
          url={this.menu.url}
          onSave={this.createItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.createEmptyItem}
          cancelGetItem={this.getItemsCancel}
          viewable={this.viewable}
          withToken={this.parent?.auth?.withToken}
          entity={this}
          header={i18n.t('General information')}
          editorFieldName="content"
        />
      );
    });
  }

  @computed
  get EditorBody() {
    return observer(({ id }: { id: string }) => {
      if (!this.canEdit) return this.forbiddenPlaceholder;

      return (
        <NewsEntityEditor
          id={id}
          fields={this.fields}
          errors={this.editorFieldErrors}
          url={this.menu.url}
          onSave={this.updateItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.getItem}
          cancelGetItem={this.getItemsCancel}
          viewable={this.viewable}
          withToken={this.parent?.auth?.withToken}
          entity={this}
          header={i18n.t('General information')}
          editorFieldName="content"
        />
      );
    });
  }
}
