import { NotificationEntity } from '~/pages/notification/components/NotificationEntity';
import { NOTIFICATION_FIELDS } from './fields';
import { UserRole } from '~/pages/user/constants';
import { fetchNotification } from './api';

const Notification = new NotificationEntity({
  title: 'История уведомлений',
  fields: NOTIFICATION_FIELDS,
  viewable: false,
  menu: {
    enabled: true,
    url: '/notification',
    label: 'История уведомлений',
  },
  api: {
    list: { url: '/admin/v1/notification', method: 'get' },
    get: { url: '/admin/v1/notification', method: 'get' },
  },
  roles: [UserRole.AppAdmin],
  permissions: {
    list: [UserRole.AppAdmin],
  },
  fetchItemsFn: fetchNotification,
});

export default Notification;
