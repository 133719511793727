import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink, useLocation, Route, Switch } from 'react-router-dom';
import styles from './styles.module.scss';
import { ApplicationSetup } from '../ApplicationSetup';
import { DounloadApp } from '../DounloadApp';
import { ApplicationInstructions } from '../ApplicationInstructions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 240,
      height: 56,
      fontSize: 15,
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
      height: '100%',
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

function getSteps() {
  return ['Шаг 1', 'Шаг 2', 'Шаг 3'];
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return 'Настройка приложения';
    case 1:
      return 'Скачивание приложения';
    case 2:
      return 'Инструкция пользования';
    default:
      return 'Unknown step';
  }
}

export default function Steps({
  getApplication,
  putApplication,
  getSetup,
  getVersion,
  addAppFile,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const location = useLocation();
  const handleNext = (step) => {
    setActiveStep(step);
  };

  const { pathname } = location;

  useEffect(() => {
    if (pathname === '/customization') {
      handleNext(0);
    }
    if (pathname === '/customization/setup') {
      handleNext(1);
    }
    if (pathname === '/customization/application-instructions') {
      handleNext(2);
    }
  }, [pathname]);

  return (
    <div className={styles.wrap}>
      <div className={styles.steps}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel className={styles.step_label}>{label}</StepLabel>
              <StepContent>
                <Typography className={styles.step_desc}>{getStepContent(index)}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={styles.wrap}>
        <Switch>
          <Route path="/customization/setup">
            <DounloadApp getSetup={getSetup} getVersion={getVersion} />
          </Route>
          <Route path="/customization/application-instructions">
            <ApplicationInstructions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleNext(0);
                }}
                className={classes.button}
                to="/customization/"
                component={RouterLink}
              >
                В начало
              </Button>
            </ApplicationInstructions>
          </Route>
          <Route path="/customization/">
            <ApplicationSetup
              getApplication={getApplication}
              putApplication={putApplication}
              addAppFile={addAppFile}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
