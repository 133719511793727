import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { observer } from 'mobx-react';
import { useEntity } from 'icerockdev-admin-toolkit';
import { Link as RouterLink } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { ModalUserBlock } from '../ModalUserBlock';
import { UserEntity } from '../UserEntity';
import styles from './styles.module.scss';
import { UserStatus } from '~/pages/user/constants';

interface UserHeadProps {
  data: Record<string, any>;
}

const UserHeadButtons = observer(({ data }: UserHeadProps) => {
  const entity = useEntity<UserEntity>();

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      entity.unBlockItem(data?.id || 0);
    },
    [data, entity]
  );

  return (
    <div className={styles.wrap}>
      <Button
        variant="contained"
        color="primary"
        to={`${entity.menu.url}/${data.id}/edit`}
        component={RouterLink}
        className={styles.header_button__edit}
      >
        <EditIcon />
      </Button>
      {data?.status === UserStatus.Active ? (
        <ModalUserBlock data={data}>
          <Button variant="contained" color="secondary" className={styles.header_button__delete}>
            <LockIcon />
          </Button>
        </ModalUserBlock>
      ) : (
        <Button
          variant="contained"
          style={{
            color: 'white',
            backgroundColor: 'green',
          }}
          className={styles.header_button__delete}
          onClick={onClick}
        >
          <LockOpenIcon />
        </Button>
      )}
    </div>
  );
});

export { UserHeadButtons };
