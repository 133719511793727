import React from 'react';
import { RenderLeafProps } from 'slate-react/dist/components/editable';
import { Text } from 'slate';
import { LeafText } from '~/pages/news/slate/types';

export interface RenderLeafPropsExtended extends RenderLeafProps {
  leaf: Text & LeafText;
}

const Leaf = ({ attributes, children, leaf }: RenderLeafPropsExtended) => {
  const textDecoration: string[] = [];
  const style: { [key: string]: string } = {};
  if (leaf?.bold) {
    style.fontWeight = 'bold';
  }
  if (leaf?.italic) {
    style.fontStyle = 'italic';
  }
  if (leaf?.strikethrough) {
    textDecoration.push('line-through');
  }
  if (leaf?.underline) {
    textDecoration.push('underline');
  }
  if (textDecoration.length) {
    style.textDecoration = textDecoration.join(' ');
  }

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
};

export { Leaf };
