import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityGetFunctionProps,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import moment from 'moment';
import { format, parseISO } from 'date-fns';
import { axios } from '~/utils/axios';
import { authorizedHandler } from '~/utils/interceptor';

export const fetchUsers: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const limit = count ? (page + 1) * count : 25;
  const orderBy = sortDir.toUpperCase();
  // noinspection TypeScriptValidateTypes
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || {};

  // fetching users
  const response = await axios.get(url, {
    params: {
      offset,
      limit,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    headers: { authorization: token },
  });

  const list = response?.data?.data || [];

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};

const getCustomerItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  });

export const getUser: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getCustomerItemData({ url, id, token });

  return {
    data: result?.data?.data || {},
    error: '',
  };
};

export const updateUser = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios
    .put(`${url}/${data.id}`, data, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};

export const createUser = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const payload = data;
  payload.subscriptionStartTime = moment(payload.subscriptionStartTime).format(
    'YYYY-MM-DD HH:mm:ss'
  );
  payload.subscriptionEndTime = moment(payload.subscriptionEndTime).format('YYYY-MM-DD HH:mm:ss');
  const result = await axios
    .post(url, payload, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};

export const createSubscription = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const payload = data;
  payload.startAt = format(parseISO(payload.startAt), 'yyyy-MM-dd HH:mm:ss');
  payload.expiredAt = format(parseISO(payload.expiredAt), 'yyyy-MM-dd HH:mm:ss');

  const result = await axios
    .post(url, payload, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};

export const blockCustomerItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.delete(`${url}/status`, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};

export const unBlockCustomerItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.put(
    `${url}/status`,
    {},
    {
      headers: { authorization: token },
    }
  );

  return {
    data: result.data,
  };
};

export const blockSubscriptionItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios
    .delete(`${url}/status`, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};

export const unBlockSubscriptionItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios
    .put(
      `${url}/status`,
      {},
      {
        headers: { authorization: token },
      }
    )
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};
