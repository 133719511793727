import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Entity } from 'icerockdev-admin-toolkit';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

interface IProps {
  data: Record<string, any> | undefined;
  children: ReactNode;
  entity: Entity & DeletableNewsEntity;
  isFeedDelete?: boolean | undefined;
  isNewsDelete?: boolean | undefined;
}

export interface DeletableNewsEntity {
  deleteNews: (id: number, feedId: number, isFeedDelete?: boolean) => Promise<void>;
}

const ModalFeedNewsDelete: FC<IProps> = ({
  data,
  children,
  entity,
  isFeedDelete,
  isNewsDelete,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };
  const history = useHistory();
  const { t } = useTranslation();
  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setOpen(false);
      entity.deleteNews(data?.id || 0, data?.feedId || 0, isFeedDelete);
      if (isFeedDelete) {
        history.push('/');
      }
      if (isNewsDelete) {
        history.push('/news');
      }
    },
    [entity, data?.id]
  );

  const modalButton = useMemo(() => {
    if (React.isValidElement(children)) {
      // noinspection TypeScriptValidateTypes
      return React.cloneElement(children, {
        onClick: (event) => {
          event.stopPropagation();
          setOpen(true);
        },
      });
    }
    return null;
  }, [children]);

  let label = 'messages:Are you sure you want to delete the news';
  if (isFeedDelete) {
    label = 'messages:Are you sure you want to delete the category';
  }

  return (
    <>
      {modalButton}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={styles.modal_title} id="alert-dialog-title">
          {t('Delete a news')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.modal_desc} id="alert-dialog-description">
            {t(label)}
            <br />
            <strong>{`"${data?.title}"`}</strong>
            {' ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={styles.button__disagree} onClick={(e) => handleClose(e)}>
            {t('buttons:Cancel')}
          </Button>
          <Button className={styles.button__agree} onClick={(e) => onClick(e)}>
            {t('buttons:Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ModalFeedNewsDelete };
