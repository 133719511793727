import React, { ReactElement } from 'react';
import { Entity, EntityBreadcrumbs } from 'icerockdev-admin-toolkit';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { FeedViewer } from '../FeedViewer';
import { getNews, deleteNewsApi, addNotificationApi, fetchNews } from '../../api';
import { NEWS_PER_PAGE } from '~/pages/feed/constants';
import { DeletableNewsEntity } from '~/application/modules/ModalFeedNewsDelete';

class FeedEntity extends Entity implements DeletableNewsEntity {
  @observable isNewsLoading = false;

  @observable isLoadMoreNewsButtonShown = true;

  @action
  showMore = async (id: number, page: number, isStart: boolean | undefined) => {
    if (!this.parent?.auth?.withToken) return;

    this.isNewsLoading = true;

    const result = await this.parent.auth.withToken(getNews, {
      url: this?.api?.get.url,
      id,
      page,
    });

    this.isLoadMoreNewsButtonShown = result.data.news.length === NEWS_PER_PAGE;

    const editorData = result.data;
    if (!isStart) {
      editorData.news = [...(this.editorData.news ?? []), ...result.data.news];
    }

    this.editorData = editorData;
    this.isNewsLoading = false;
  };

  @action
  deleteNews = async (id: number, feedId: number, isFeedDelete: boolean | undefined) => {
    if (!this.parent?.auth?.withToken) return;

    if (isFeedDelete) {
      await this.parent.auth.withToken(deleteNewsApi, {
        url: this?.api?.deleteFeed.url,
        data: id,
      });

      const result = await this.parent.auth.withToken(fetchNews, {
        url: this?.api?.get.url,
      });

      this.data = result.data.list;
    } else {
      await this.parent.auth.withToken(deleteNewsApi, {
        url: this?.api?.delete.url,
        data: id,
      });

      const result = await this.parent.auth.withToken(getNews, {
        url: this?.api?.get.url,
        id: feedId,
      });

      this.editorData = result.data;
    }
  };

  @action
  addNotification = (data) => {
    if (!this.parent?.auth?.withToken) return;

    this.parent.auth.withToken(addNotificationApi, {
      url: this?.api?.addNotification.url,
      data: { ...data, userId: this.parent?.auth?.user.id },
    });
  };

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <EntityBreadcrumbs
          data={{ ...this.editorData, name: `${i18n.t('Section')}: ${this.editorData?.name}` }}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={false}
        />
      )
    );
  }

  @computed
  get Viewer() {
    return observer(({ id }: { id: string }) => (
      <>
        <this.ViewerHead id={id} />
        <this.FeedViewerBody id={Number(id)} data={this.editorData} />
        <this.ViewerFooter id={id} />
      </>
    ));
  }

  @computed
  get FeedViewerBody() {
    return observer(({ id, data }: { id: number; data: Record<string, any> }) => (
      <FeedViewer
        id={id}
        isLoading={this.isNewsLoading}
        isButtonShown={this.isLoadMoreNewsButtonShown}
        data={data}
      />
    ));
  }
}

export { FeedEntity };
