import { CustomizationPage } from '~/pages/customization/components/CustomizationPage';
import { UserRole } from '~/pages/user/constants';

const Account = new CustomizationPage({
  title: 'Генерация приложения',
  menu: {
    enabled: true,
    url: '/customization',
    label: 'Генерация приложения',
  },
  roles: [UserRole.AppAdmin],
});

export default Account;
