import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useConfig, IEntityGetFunctionResult } from 'icerockdev-admin-toolkit';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { AppAdminAccount } from '~/application/containers/auth/AppAdminAccount';
import { VerticalNavigation } from '~/application/containers/layout/VerticalNavigation';
import { getCheckSubscriptionExpire } from '~/pages/account/api';
import { API_URLS } from '~/utils/constants';

interface IProps {}

const AppAdminLayout: FC<IProps> = observer(({ children }) => {
  const config = useConfig();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const result: IEntityGetFunctionResult = await config.auth?.withToken(
        getCheckSubscriptionExpire,
        {
          url: API_URLS.subscription.check,
        }
      );

      let label = '';
      const expire = result.data;
      if (expire?.days === 3 && expire?.months === 0 && expire?.years === 0) {
        label = t('messages:Until the end of the subscription is left 3 days');
      }
      if (expire?.days === 7 && expire?.months === 0 && expire?.years === 0) {
        label = t('messages:Until the end of the subscription is left 1 week');
      }
      if (expire?.days === 0 && expire?.months === 1 && expire?.years === 0) {
        label = t('messages:Until the end of the subscription is left 1 month');
      }
      if (label !== '') {
        config.notifications.showError(label);
      }
    })();
  }, []);

  return (
    <div className={classNames(styles.layout, 'app-admin-layout')}>
      <div className={classNames(styles.menu, 'app-admin-layout__menu')}>
        <div className={classNames(styles.logo, 'app-admin-layout__logo')}>
          {config.logo ? (
            <Link to={config.fallbackUrl}>
              <img src={config.logo} alt="logo" />
            </Link>
          ) : null}
        </div>

        <div className={classNames(styles.navigation, 'app-admin-layout__navigation')}>
          <VerticalNavigation />
        </div>

        {!!config.auth && (
          <div className={classNames(styles.account, 'app-admin-layout__account')}>
            {!!config.auth && <AppAdminAccount />}
          </div>
        )}
      </div>

      <div className={classNames(styles.content, 'app-admin-layout__content')}>{children}</div>
    </div>
  );
});

export { AppAdminLayout };
