import React, { useCallback, useState, FC, useEffect } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import { useEntity, IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { NewsEntity } from '../NewsEntity';
// eslint-disable-next-line import/extensions
import noImage from '~/assets/no_image.svg';
import styles from './styles.module.scss';

export const NewsImageField: FC<IEntityFieldProps> = ({ label, value, handler }) => {
  const locale = useLocation();
  const { t } = useTranslation();
  const entity = useEntity<NewsEntity>();
  const [previewImg, setPreviewImg] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = useCallback(async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    if (file.size > 10485760) {
      alert(t('messages:The image must not exceed 10Mb'));
      e.target.files = null;
    } else {
      setIsLoading(true);

      const result = await entity.addNewsFile(formData);

      setPreviewImg(result.data.data);

      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!handler) return;
    handler(previewImg || null);
  }, [previewImg]);

  useEffect(() => {
    if (!value) return;
    setPreviewImg(value || null);
  }, [value]);

  useEffect(() => {
    if (locale.pathname.indexOf('create') !== -1) {
      setPreviewImg('');
    }
  }, [locale.pathname]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.icon_list}>
      <div className={styles.icon_block}>
        {value ? (
          <div className={styles.icon_block__img}>
            <img src={previewImg} alt="" />
          </div>
        ) : (
          <div className={styles.icon_block__no_img}>
            <img src={noImage} alt={t('No image')} />
          </div>
        )}
        <div className={styles.icon_block__text}>
          <div>
            <h3 className={styles.title}>{label}</h3>
          </div>
          <Button style={{ color: 'green' }}>
            <label htmlFor="newsImage">
              <GetAppIcon />
              Загрузить
              <input
                className={styles.input_file}
                type="file"
                id="newsImage"
                onChange={(e) => {
                  handleChange(e);
                }}
                onClick={(e) => {
                  (e.target as HTMLInputElement).value = '';
                }}
                name="newsImage"
              />
            </label>
          </Button>
        </div>
      </div>
    </div>
  );
};
