import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEntity } from 'icerockdev-admin-toolkit';
import { Subscription } from '~/pages/user/constants';
import styles from './styles.module.scss';
import { UserEntity } from '~/pages/user/components/UserEntity';

interface SubscriptionModalProps {
  userId: number;
  item: Subscription;
  children: ReactNode;
}

const SubscriptionModal: FC<SubscriptionModalProps> = ({ userId, item, children }) => {
  const entity = useEntity<UserEntity>();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setOpen(false);
      entity.blockSubscription(userId, item.id);
    },
    [entity, item]
  );

  const modalButton = useMemo(() => {
    if (React.isValidElement(children)) {
      // noinspection TypeScriptValidateTypes
      return React.cloneElement(children, { onClick: () => setOpen(true) });
    }
    return null;
  }, [children]);

  return (
    <>
      {modalButton}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={styles.modal_title} id="alert-dialog-title">
          {t('Subscription blocking')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.modal_desc} id="alert-dialog-description">
            {`${t('messages:Are you sure you want to block the subscription from')} `}
            {`${moment(item.startAt).format('DD.MM.YYYY')}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={styles.button__disagree} onClick={() => setOpen(false)}>
            {t('buttons:Cancel')}
          </Button>
          <Button className={styles.button__agree} onClick={(e) => onClick(e)}>
            {t('buttons:Block')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { SubscriptionModal };
