import { EntityViewer } from 'icerockdev-admin-toolkit';
import React, { FC, useMemo } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { AccountEntity } from '~/pages/account/components/AccountEntity';
import styles from './styles.module.scss';
import { SubscriptionHistory } from '~/pages/account/components/SubscriptionHistory';
import { User } from '~/pages/user/constants';
import { SubscriptionRemaining } from '~/pages/account/components/SubscriptionRemaining';

interface AccountProps {
  entity: AccountEntity;
  data: User;
}

const Account: FC<AccountProps> = ({ entity, data }) => {
  const fullName = useMemo(
    () => [data?.firstName, data?.middleName, data?.lastName].join(' '),
    [data]
  );

  if (entity.isLoading) {
    return (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.wrap}>
      <Typography className={styles.username} variant="h4">
        {fullName}
      </Typography>
      {data && (
        <div className={styles.entity}>
          <EntityViewer
            id={String(data.id)}
            fields={entity.fields}
            url={entity.menu.url}
            errors={{}}
            onSave={() => null}
            onCancel={() => null}
            onResetFieldError={() => null}
            isEditing={false}
            isLoading={false}
            setEditorData={() => null}
            data={data}
            getItem={() => null}
            cancelGetItem={() => null}
            withToken={() => null}
            viewable
            entity={entity}
          />
        </div>
      )}
      <SubscriptionRemaining data={data.subscription} />
      <SubscriptionHistory data={data.subscriptionHistory} />
    </div>
  );
};

export { Account };
