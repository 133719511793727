import { IEntityField } from 'icerockdev-admin-toolkit';
import { DeliveredCountField } from './components/DeliveredCountField';
import { CreatedAtField } from './components/CreatedAtField';

export const NOTIFICATION_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'title',
    label: 'Заголовок уведомления',
    required: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'body',
    label: 'Сообщение',
    required: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'url',
    label: 'Ссылка на новость',
    required: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    name: 'deliveredCount',
    label: 'Кол-во доставленных',
    required: true,
    hideInEdit: true,
    component: DeliveredCountField,
  },
  {
    type: 'string',
    name: 'createdAt',
    label: 'Дата/время создания',
    required: true,
    hideInEdit: true,
    component: CreatedAtField,
  },
];
