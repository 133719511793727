import React, { FC } from 'react';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

interface IProps {
  firstChildren: React.ReactNode;
  secondChildren: React.ReactNode;
}

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabsView: FC<IProps> = ({ firstChildren, secondChildren }) => {
  const [tabValue, setTabValue] = React.useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const { t } = useTranslation();

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Paper>
            <Box>{children}</Box>
          </Paper>
        )}
      </div>
    );
  };

  const a11yProps = (index: any) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  return (
    <>
      <Tabs
        TabIndicatorProps={{ style: { background: '#4253AF' } }}
        value={tabValue}
        className={styles.wrap_tabs}
        onChange={handleChangeTab}
      >
        <Tab label={t('General')} {...a11yProps(0)} />
        <Tab label={t('Subscription history')} {...a11yProps(1)} />
      </Tabs>
      <Box>
        <TabPanel value={tabValue} index={0}>
          <div className={styles.wrap_box}>{firstChildren}</div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {secondChildren}
        </TabPanel>
      </Box>
    </>
  );
};

export { TabsView };
