import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { NotificationEntity } from '~/pages/notification/components/NotificationEntity';

interface IProps extends IEntityFieldProps {
  entity: NotificationEntity;
}

const DeliveredCountField: FC<IProps> = ({ data }) => {
  const deliveredIosCount = data?.deliveredIosCount ? data.deliveredIosCount : 0;
  const deliveredAndroidCount = data?.deliveredAndroidCount ? data.deliveredAndroidCount : 0;

  return <div>{`IOS: ${deliveredIosCount} | Android: ${deliveredAndroidCount}`}</div>;
};

export { DeliveredCountField };
