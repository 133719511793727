import {
  IEntityGetFunction,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const getApplicationApi: IEntityGetFunction = async ({ url, token }) => {
  const result = await axios.get(`${url}`, {
    headers: {
      authorization: token,
    },
  });

  return {
    data: result.data?.data || {},
  };
};

export const getSetupApi: IEntityGetFunction = async ({ url, token }) => {
  const result = await axios.get(`${url}`, {
    headers: {
      authorization: token,
    },
    responseType: 'blob',
  });

  return {
    data: result.data || {},
  };
};

export const getVersionApi: IEntityGetFunction = async ({ url, token }) => {
  const result = await axios.get(`${url}`, {
    headers: {
      authorization: token,
    },
  });

  return {
    data: result.data?.data || {},
  };
};

export const putApplicationApi = async ({ url, token, data }) => {
  await axios.put(`${url}`, data, {
    headers: { authorization: token },
  });
};

export const addAppFileApi = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(url, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};
