/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

const ApplicationInstructions = observer(({ children }) => (
  <div className={styles.wrap}>
    <h3 className={styles.title}>Инструкция после генерации</h3>
    <div className={styles.instruction_wrap}>
      <h3 className={styles.instruction_text}>
        Для продолжения следуйте следующим шагам инструкции:
      </h3>
      <ol className={styles.instruction_list}>
        <li className={styles.instruction_text}>
          Настройка PUSH уведомления
          {' ('}
          <a href="https://skyur.app/push/" target="_blank" rel="noreferrer">
            Инструкция
          </a>
          {')'}
        </li>
        <li className={styles.instruction_text}>
          Установка и настройка Android Studio (
          <a href="https://skyur.app/androidstudio/" target="_blank" rel="noreferrer">
            Инструкция
          </a>
          )
        </li>
        <li className={styles.instruction_text}>
          Установить и настроить Xcode (
          <a href="https://skyur.app/xcode/" target="_blank" rel="noreferrer">
            Инструкция
          </a>
          )
        </li>
        <li className={styles.instruction_text}>
          Java Development Kit (JDK) и CocoaPods и сборка приложения Adnroid (
          <a href="https://skyur.app/jdk/" target="_blank" rel="noreferrer">
            Инструкция
          </a>
          )
        </li>
        <li className={styles.instruction_text}>
          Сборка проекта IOS (
          <a href="https://skyur.app/ios/" target="_blank" rel="noreferrer">
            Инструкция
          </a>
          )
        </li>
        <li className={styles.instruction_text}>
          Выкладываем в Google (
          <a href="https://skyur.app/playconsole/" target="_blank" rel="noreferrer">
            Инструкция
          </a>
          )
        </li>
      </ol>
      <h3 className={styles.instruction_text}>
        Если возникли сложности с прохождением этих шагов обратитесь по
        {' '}
        <a href="mailto:support@skyur.app">support@skyur.app</a>
      </h3>
    </div>
    <h3 className={styles.title}>Инструкция для обновления</h3>
    <div className={styles.instruction_wrap}>
      <h3 className={styles.instruction_text}>
        Необходимо производить обновления с того же устройства что и первичную сборку по данным
        шагам:
      </h3>
      <ol className={styles.instruction_list}>
        <li className={styles.instruction_text}>
          Повторная сборка Android приложения и отправка в Google play console (
          <a href="https://skyur.app/updandroid" target="_blank" rel="noreferrer">
            Инструкция
          </a>
          )
        </li>
        <li className={styles.instruction_text}>
          Повторная сборка iOS и отправка в Apple store Connect (
          <a href="https://skyur.app/updios" target="_blank" rel="noreferrer">
            Инструкция
          </a>
          )
        </li>
      </ol>
      <h3 className={styles.instruction_text}>
        Если возникли сложности с прохождением этих шагов обратитесь по
        {' '}
        <a href="mailto:support@skyur.app">support@skyur.app</a>
      </h3>
    </div>
    <div className={styles.button_next}>{children}</div>
  </div>
));

export { ApplicationInstructions };
