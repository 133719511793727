import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useConfig } from 'icerockdev-admin-toolkit';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface IProps {}

const AuthVerticalLayout: FC<IProps> = observer(({ children }) => {
  const config = useConfig();
  const splash = config.auth?.splash || '';

  return (
    <div className={styles.layout}>
      <div className={styles.left}>
        <div className={styles.left__wrap}>
          <div className={styles.logo}>
            {config.logo ? (
              <Link to={config.fallbackUrl}>
                <img src={config.logo} alt="logo" />
              </Link>
            ) : null}
          </div>

          <div className={styles.form}>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>

      <div className={styles.image} style={{ backgroundImage: `url('${splash}')` }} />
    </div>
  );
});

export { AuthVerticalLayout };
