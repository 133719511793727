import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  label: string;
}

const EntityListEmptyDataPlaceholder: FC<IProps> = ({ label }) => (
  <div className={styles.wrap}>{label}</div>
);

export { EntityListEmptyDataPlaceholder };
