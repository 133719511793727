import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Route, Router, Switch } from 'react-router-dom';
import { useConfig } from 'icerockdev-admin-toolkit';

const AuthRouter: FC = observer(() => {
  const config = useConfig();
  const { auth } = config;

  if (!auth) return <></>;

  return (
    <ThemeProvider theme={config.themeInstance}>
      <CssBaseline />

      <Router history={config.history}>
        <auth.layout>
          <Switch>
            <Route path="/signup/:token" component={auth.signUp} />
            <Route path="/restore" component={auth.forgotPassword} />
            <Route path="/reset/:token" component={auth.resetPassword} />
            <auth.signIn />
          </Switch>
        </auth.layout>
      </Router>

      <config.notifications.Output />
    </ThemeProvider>
  );
});

export { AuthRouter };
