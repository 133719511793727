import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { EntityList } from '~/application/modules/EntityList';
import { EntityListEmptyDataPlaceholder } from '~/application/modules/EntityListEmptyDataPlaceholder';

export class NotificationEntity extends Entity {
  @computed
  get ListBody() {
    return observer(() => (
      <EntityList
        fields={this.fields}
        data={this.data}
        extra={this.ListExtra}
        isLoading={this.isLoading}
        url={this.menu.url}
        selected={this.selected}
        sortBy={this.sortBy}
        sortDir={this.sortDir}
        canView={this.viewable && this.canView}
        canEdit={this.editable && this.canEdit}
        canSelect={this.selectable}
        setSelected={this.setSelected}
        onSortChange={this.setSort}
        withToken={this.parent?.auth?.withToken}
        entity={this}
        emptyDataPlaceholder={
          <EntityListEmptyDataPlaceholder label={i18n.t('messages:No notification')} />
        }
      />
    ));
  }
}
