import HttpStatusCode from 'http-status-typed';
import { UNAUTHORIZED } from 'icerockdev-admin-toolkit';
import { AxiosError } from 'axios';
import path from 'ramda/es/path';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import NotificationError from '~/utils/notificationError';

const unauthorizedErrorInterceptor = (e: AxiosError) => {
  if (e?.response?.status === HttpStatusCode.UNAUTHORIZED) {
    throw new Error(UNAUTHORIZED);
  }
};

const validationErrorInterceptor = (e: AxiosError) => {
  const validationError = path(['response', 'data', 'data', 0, 'message'], e) as string;
  if (validationError) {
    throw new NotificationError(i18n.t('Error'), validationError);
  }
};

const internetConnectionErrorInterceptor = (e: AxiosError) => {
  if (!e?.response) {
    throw new NotificationError(i18n.t('Error'), i18n.t('messages:No internet connection'));
  }
};

const anyCodeErrorInterceptor = (e: AxiosError) => {
  if (e?.response?.data?.message) {
    throw new NotificationError(i18n.t('Error'), e?.response?.data?.message);
  }
};

export const projectErrorInterceptor = (e: AxiosError): never => {
  if (!e?.code) {
    internetConnectionErrorInterceptor(e);
  }
  throw e;
};

export const unauthorizedHandler = (e: AxiosError): never => {
  validationErrorInterceptor(e);
  anyCodeErrorInterceptor(e);
  internetConnectionErrorInterceptor(e);
  throw e;
};

export const authorizedHandler = (e: AxiosError): never => {
  unauthorizedErrorInterceptor(e);
  validationErrorInterceptor(e);
  anyCodeErrorInterceptor(e);
  internetConnectionErrorInterceptor(e);
  throw e;
};
