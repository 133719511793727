type formInput = Record<string, string>;

const formInputs: formInput[] = [
  {
    nameImput: 'name',
    label: 'Название приложения',
  },
  {
    nameImput: 'packageName',
    label: 'packageName',
  },
  {
    nameImput: 'privacyPolicyUrl',
    label: 'privacyPolicyUrl',
  },
  {
    nameImput: 'fcmServerKey',
    label: 'fcmServerKey',
  },
  {
    nameImput: 'appStoreSharedKey',
    label: 'appStoreSharedKey',
  },
];

export default formInputs;
