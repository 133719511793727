import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './styles.module.scss';
import { FeedEntity } from '~/pages/feed/components/FeedEntity';
import { ModalFeedNewsDelete } from '~/application/modules/ModalFeedNewsDelete';

const ButtonDeleteFeed: FC<IEntityFieldProps> = ({ data }) => {
  const entity = useEntity<FeedEntity>();

  const editData = { title: data?.name, id: data?.id };

  return (
    <div className={styles.wrap}>
      <ModalFeedNewsDelete data={editData} isFeedDelete entity={entity}>
        <Button color="secondary">
          <DeleteIcon />
        </Button>
      </ModalFeedNewsDelete>
    </div>
  );
};

export { ButtonDeleteFeed };
