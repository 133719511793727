import React, { FC, useCallback, useRef } from 'react';
import { useConfig } from 'icerockdev-admin-toolkit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const AppAdminAccount: FC = observer(() => {
  const { t } = useTranslation();
  // noinspection TypeScriptValidateTypes
  const ref = useRef<HTMLDivElement>(null);
  const config = useConfig();
  const onLogout = useCallback(() => {
    if (!window.confirm(t('messages:Do you really want to leave?'))) return;
    config.auth?.logout();
  }, [config.auth]);

  return (
    <div className={styles.account} ref={ref}>
      <div className={styles.left}>
        <div className={styles.name}>{config.auth?.userName}</div>
        <div className={styles.role}>{config.auth?.userRoleTitle}</div>
      </div>
      <button type="button" className={styles.logout} onClick={onLogout}>
        <ExitToAppIcon />
      </button>
    </div>
  );
});

export { AppAdminAccount };
