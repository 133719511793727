import {
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityGetFunctionProps,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import moment from 'moment';
import { axios } from '~/utils/axios';
import NotificationError from '~/utils/notificationError';
import { authorizedHandler } from '~/utils/interceptor';

export const fetchNews: IEntityFetchFunction = async ({ url, token, page = 0, count }) => {
  const offset = page && count ? page * count : 0;
  const limit = count ? (page + 1) * count : 25;
  // fetching Notification
  const response = await axios.get(url, {
    params: {
      offset,
      limit,
    },
    headers: { authorization: token },
  });

  const list = response?.data?.data || [];

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};

const getNewsItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  });

export const getNews: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getNewsItemData({ url, id, token });

  return {
    data: result?.data?.data || {},
    error: '',
  };
};

export const createNews = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const payload = data;
  payload.publishedAt = moment(payload.publishedAt).format('YYYY-MM-DD HH:mm:ss');
  const result = await axios
    .post(url, payload, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};

export const updateNews = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios
    .put(`${url}/${data.id}`, data, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};

export const deleteNewsApi = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.delete(`${url}/${data}`, {
    headers: { authorization: token },
  });

  if (result?.data.success === 'false') {
    throw new NotificationError(result?.data.status, result?.data.message);
  }

  return {
    data: result.data,
  };
};

export const addFileNewsApi = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios
    .post(url, data, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};

export const addNotificationApi = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios
    .post(url, data, {
      headers: { authorization: token },
    })
    .catch(authorizedHandler);

  return {
    data: result.data,
  };
};
