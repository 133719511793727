import React, { ChangeEvent, FC, useState, useCallback } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  value: string;
  label?: string;
  name: string;
  hasError?: boolean;
  handler: (e: ChangeEvent<HTMLInputElement>) => void;
}

const TextInput: FC<IProps> = ({ value, label, name, hasError, handler }) => {
  const [focused, setFocused] = useState(false);
  const onFocus = useCallback(() => setFocused(true), [setFocused]);
  const onBlur = useCallback(() => setFocused(false), [setFocused]);

  return (
    <div className={classNames(styles.wrap, { [styles.has_error]: hasError })}>
      <div
        className={classNames(styles.input, {
          [styles.focused]: focused || value,
          [styles.without_label]: !label,
        })}
      >
        {label && <div className={styles.input__label}>{label}</div>}

        <div className={styles.input__error}>Заполните это поле</div>

        <input
          type="text"
          name={name}
          value={value}
          onChange={handler}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export { TextInput };
