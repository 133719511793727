import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import moment from 'moment';
import { UserEntity } from '~/pages/user/components/UserEntity';

interface IProps extends IEntityFieldProps {
  entity: UserEntity;
}

// TODO: Move to pages/subscription/components
const SubscriptionEndTimeField: FC<IProps> = ({ data }) => {
  const expiredAt = data?.subscription?.expiredAt
    ? moment(data?.subscription?.expiredAt).format('DD.MM.YYYY')
    : '-';

  return <div>{`${expiredAt}`}</div>;
};

export { SubscriptionEndTimeField };
